enum ViewMode {
  IDLE = 'IDLE',
  ADD = 'ADD',
  EDIT = 'EDIT'
}

export const viewModeType = {
  IDLE: ViewMode.IDLE,
  ADD: ViewMode.ADD,
  EDIT: ViewMode.EDIT
}
