import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { ErrorData, ErrorState } from 'features/error/errorSlice'
import { PolicyData, PolicyScope } from 'features/policies/policySlice'
import { RuleData } from 'features/rules/ruleSlice'

import { getEmployeesAPI } from 'utils/api'
import { EmployeeStatusType } from 'utils/constant/filterStatus'
import { viewModeType } from 'utils/constant/viewMode'

export interface EmployeeData {
  id: number
  company_id: number
  full_name: string
  emp_number: string
  phone: string
  email: string
  group_name: string
  group_id: number
  policies: {
    transport: {
      limit: Pick<
        PolicyData,
        'id' | 'name' | 'status' | 'start_date' | 'end_date'
      >[]
      rules: Pick<RuleData, 'id' | 'name'>[]
    }
    logistic: {
      limit: Pick<
        PolicyData,
        'id' | 'name' | 'status' | 'start_date' | 'end_date'
      >[]
      rules: null
    }
    food: {
      limit: Pick<
        PolicyData,
        'id' | 'name' | 'status' | 'start_date' | 'end_date'
      >[]
      rules: null
    }
  }
  status: string
  isEmailEditable: boolean
}

export interface EmployeeFilter {
  query: string
  status: string
  groups: Array<string>
}

export interface EmployeeState {
  isLoading: boolean
  employees: EmployeeData[]
  total: number
  itemsPerPage: number
  currentPage: number
  viewMode: string
  filter: EmployeeFilter
  errors: ErrorData
}

export type GetListAPIPayload =
  | {
      limit?: number
      query?: string
      status?: string
      group_ids?: string
      policy_ids?: string
      ids?: string
      exclude_ids?: string
      offset?: number
      scope?: PolicyScope
    }
  | undefined

export const initialState: EmployeeState = {
  isLoading: false,
  employees: [],
  total: 0,
  itemsPerPage: 10,
  currentPage: 1,
  filter: {
    query: '',
    status: EmployeeStatusType.ALL,
    groups: []
  },
  viewMode: viewModeType.IDLE,
  errors: null
}

export const getEmployees = createAsyncThunk(
  'employee/getEmployees',
  async (payload: GetListAPIPayload, { rejectWithValue, getState }) => {
    try {
      const getFilter: any = (getState() as RootState).employee.filter
      const { groups, status, query } = getFilter
      if (query !== '') payload = { ...payload, query }
      if (status !== EmployeeStatusType.ALL) payload = { ...payload, status }
      if (groups && groups.length)
        payload = { ...payload, group_ids: groups.toString() }
      return await getEmployeesAPI(payload)
    } catch (error) {
      const { status, data } = error.response
      if (data || status) return rejectWithValue({ data, status })
      return error
    }
  },
  {
    condition: (_, { getState }) => {
      const { error } = getState() as {
        error: ErrorState
      }
      if (
        error.status === 429 &&
        Math.floor(Date.now() / 1000) <= error.updateAt!!
      ) {
        return false
      }
    },
    dispatchConditionRejection: true
  }
)

export const employeeSlice = createSlice({
  name: 'employee',
  initialState: initialState,
  reducers: {
    updateEmployee: (state, action) => {
      const { id, full_name, emp_number, group_id, group_name } = action.payload
      const existingEmployee = state.employees.find(
        (employee) => employee.id === id
      )
      if (existingEmployee) {
        const { email = existingEmployee.email } = action.payload
        existingEmployee.full_name = full_name
        existingEmployee.emp_number = emp_number
        existingEmployee.email = email
        existingEmployee.group_id = group_id
        existingEmployee.group_name = group_name
      }
    },
    setFilter: (state, action) => {
      const { name, value } = action.payload
      const {
        filter: { groups }
      } = state
      if (name === 'status') {
        state.filter.status = value
      } else if (name === 'query') {
        state.filter.query = value
      } else if (value === EmployeeStatusType.ALL) {
        state.filter.groups = initialState.filter.groups
      } else if (groups.includes(value)) {
        let filterGroup = groups.filter((group) => group !== value)
        state.filter.groups = filterGroup
      } else {
        state.filter.groups = [...state.filter.groups, value]
      }
    },
    setViewMode: (state, action) => {
      const { payload } = action
      state.viewMode = payload || initialState.viewMode
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload
    },
    setItemsPerPage: (state, action) => {
      state.itemsPerPage = action.payload
    },
    resetPagination: (state) => {
      state.itemsPerPage = initialState.itemsPerPage
      state.currentPage = initialState.currentPage
    },
    resetState: () => initialState
  },
  extraReducers: (builder) => {
    builder.addCase(getEmployees.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getEmployees.fulfilled, (state, action) => {
      state.isLoading = false
      state.errors = null
      const { total, employees } = action.payload.data
      state.employees = employees
      state.total = total
      const isFilterChanged =
        JSON.stringify(state.filter.groups) !==
          JSON.stringify(initialState.filter.groups) ||
        state.filter.status !== initialState.filter.status ||
        state.filter.query !== initialState.filter.query
      if (
        (total === 0 && !isFilterChanged) ||
        state.viewMode === viewModeType.ADD
      ) {
        state.viewMode = viewModeType.ADD
      } else {
        state.viewMode = viewModeType.IDLE
      }
    })
    builder.addCase(
      getEmployees.rejected,
      (state, action: PayloadAction<any, string, any, any>) => {
        state.employees = initialState.employees
        state.total = initialState.total
        state.viewMode = initialState.viewMode
        state.isLoading = false
        state.errors = action.payload || {
          status: 500,
          data: `${action.error.name}: ${action.error.message}`
        }
      }
    )
  }
})

export const {
  updateEmployee,
  setCurrentPage,
  setItemsPerPage,
  resetPagination,
  setFilter,
  setViewMode,
  resetState
} = employeeSlice.actions

export const employeeSelector = (state: RootState) => state.employee

export default employeeSlice.reducer
