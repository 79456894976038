import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { RootState } from 'app/store'
import { ErrorData, ErrorState } from 'features/error/errorSlice'
import { getHelpCenterListAPI } from 'utils/api'
import { enUS, idID, viVN } from 'utils/constant/country'

export interface TranslationData {
  [enUS]: string
  [idID]: string
  [viVN]: string
}
export interface MarkdownData {
  content: TranslationData | string
  isEmpty: boolean
  excerpt: string
  title: TranslationData | string
  name: string
  body: TranslationData | string
}

export interface CategoryMarkdownData extends MarkdownData {
  sections: string
}

export interface ArticleMarkdownData
  extends CategoryMarkdownData,
    MarkdownData {
  countries: string[]
  categories: string
  freq_rating: number
}

export interface HelpCenterData {
  articles: ArticleMarkdownData[]
  categories: CategoryMarkdownData[]
  sections: MarkdownData[]
}

export interface HelpCenterState {
  popup: {
    page: string
    detailArticle: string
    keyword: string
  }
  data: null | HelpCenterData
  isLoading: boolean
  errors: ErrorData
}

export const initialState: HelpCenterState = {
  popup: {
    page: 'HOME',
    detailArticle: '',
    keyword: ''
  },
  data: null,
  isLoading: false,
  errors: null
}

export const getHelpCenterList = createAsyncThunk(
  'helpCenter/list',
  async (_, { rejectWithValue }) => {
    try {
      return await getHelpCenterListAPI()
    } catch (error) {
      const { status, data } = error.response
      if (data || status) return rejectWithValue({ data, status })
      return error
    }
  },
  {
    condition: (_, { getState }) => {
      const { error } = getState() as {
        error: ErrorState
      }
      if (
        error.status === 429 &&
        Math.floor(Date.now() / 1000) <= error.updateAt!
      ) {
        return false
      }
    },
    dispatchConditionRejection: true
  }
)

export const helpCenterSlice = createSlice({
  name: 'helpCenter',
  initialState: initialState,
  reducers: {
    setPopupPage: (state, action) => {
      state.popup.page = action.payload
    },
    setPopupDetailArticle: (state, action) => {
      state.popup.detailArticle = action.payload
    },
    setPopupKeyword: (state, action) => {
      state.popup.keyword = action.payload
    },
    resetPopupState: (state) => {
      state.popup = initialState.popup
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getHelpCenterList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getHelpCenterList.fulfilled, (state, action) => {
        state.isLoading = false
        state.errors = null
        state.data = action.payload
        state.popup = initialState.popup
      })
  }
})

export const {
  setPopupDetailArticle,
  setPopupKeyword,
  setPopupPage,
  resetPopupState
} = helpCenterSlice.actions

export const helpCenterSelector = (state: RootState) => state.helpCenter

export default helpCenterSlice.reducer
