import { removeAuthStorage, storage } from './storage'
import { ProfilData } from 'features/auth/authSlice'

export type SetUserData = {
  token: string
  user_profile: ProfilData
}

export const setUser = (data: SetUserData) => {
  const { token, user_profile } = data
  storage.set('_token', token)
  storage.set('_username', user_profile?.full_name)
  storage.set('_profile', JSON.stringify(user_profile))
}

export const getToken = () => {
  try {
    return storage.get('_token')
  } catch (error) {
    removeAuthStorage()
    return null
  }
}

export const getUsername = () => {
  try {
    return storage.get('_username') !== null ? storage.get('_username') : ''
  } catch (error) {
    removeAuthStorage()
    return ''
  }
}

export const getProfile = () => {
  try {
    return JSON.parse(storage.get('_profile'))
  } catch (e) {
    removeAuthStorage()
    return null
  }
}

const parseJWT = (token: string) => {
  try {
    return JSON.parse(atob(token.split('.')[1]))
  } catch (e) {
    return null
  }
}

export const isJWTFormat = (token: string) => {
  try {
    return token.split('.').length === 3
  } catch (e) {
    return false
  }
}

export const isExpired = (token: string) => {
  const data = parseJWT(token)
  if (data !== null)
    return data && data.exp && data.exp < Math.floor(Date.now() / 1000)
  return true
}
