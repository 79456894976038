import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from 'app/store'
import { ErrorData, ErrorState } from 'features/error/errorSlice'
import { enUS, idID, LocaleCode, viVN } from 'utils/constant/country'
import { getTripConfigAPI } from 'utils/api'

export interface TripReasonData {
  id?: string
  en: string
  vi?: string
}
export interface TripReasonInputData {
  ind?: string
  eng: string
  vie?: string
  showIdTranslation?: string
  showViTranslation?: string
}
export interface TripConfigPayloadData {
  trip_code_required: boolean
  trip_reason_required: boolean
  trip_reasons: TripReasonData[]
  trip_description_required: boolean
  ride_share_info_required: boolean
}
export interface TripConfigFeatureEnabledData {
  trip_code_feature_enabled: boolean
  ride_share_feature_enabled: boolean
}
export interface TripConfigData
  extends TripConfigFeatureEnabledData,
    TripConfigPayloadData {
  logo_url: string
}

interface MobilePreviewTranslationData {
  heading: string
  description: string
  trip_reason_input_placeholder: string
  trip_description_input_placeholder: string
  trip_code_heading: string
  trip_code_input_placeholder: string
  ride_share_heading: string
  ride_share_input_placeholder: string
  cta: {
    cancel: string
    order: string
  }
}
interface MobilePreviewTranslation {
  [enUS]: MobilePreviewTranslationData
  [idID]: MobilePreviewTranslationData
  [viVN]: MobilePreviewTranslationData
}
export interface TripDetailSettingState {
  mobilePreview: {
    language: LocaleCode
    translation: MobilePreviewTranslation
    trip_reasons: TripReasonInputData[]
    trip_code_required: boolean
    trip_reason_required: boolean
    trip_description_required: boolean
    ride_share_info_required: boolean
  }
  tripConfig: TripConfigData
  isLoading: boolean
  errors: ErrorData
}

export const initialState: TripDetailSettingState = {
  mobilePreview: {
    language: enUS,
    translation: {
      [enUS]: {
        heading: 'Add trip detail',
        description: 'This information will be used for the company report',
        trip_reason_input_placeholder: 'Any other reason?',
        trip_description_input_placeholder: 'Enter trip reason detail',
        trip_code_heading: 'Trip code',
        trip_code_input_placeholder:
          'Enter trip code from your company admin...',
        ride_share_heading:
          "Number of colleagues you're sharing this ride with",
        ride_share_input_placeholder: 'Enter the total number here (e.g. 2)',
        cta: {
          cancel: 'Cancel',
          order: 'Continue booking'
        }
      },
      [viVN]: {
        heading: 'Thêm chi tiết chuyến đi',
        description: 'Thông tin này sẽ được sử dụng cho báo cáo của công ty',
        trip_reason_input_placeholder: 'Bất kỳ lý do nào khác?',
        trip_description_input_placeholder: 'Nhập chi tiết lý do chuyến đi',
        trip_code_heading: 'Mã chuyến đi',
        trip_code_input_placeholder: 'Nhập mã chuyến đi do Quản trị viên cấp',
        ride_share_heading: 'Số đồng nghiệp mà bạn đang chia sẻ chuyến đi này',
        ride_share_input_placeholder: 'Nhập tổng số ở đây (ví dụ: 2)',
        cta: {
          cancel: 'Hủy bỏ',
          order: 'Tiếp tục đặt chuyến'
        }
      },
      [idID]: {
        heading: 'Tambah detail perjalanan',
        description: 'Info ini akan dipakai untuk laporan kantor',
        trip_reason_input_placeholder: 'Tulis alasan lain',
        trip_description_input_placeholder: 'Masukan detail alasan perjalanan',
        trip_code_heading: 'Kode perjalanan',
        trip_code_input_placeholder:
          'Masukan kode perjalanan dari admin kantor...',
        ride_share_heading: 'Ada berapa rekan kerja yang naik kendaraan ini?',
        ride_share_input_placeholder:
          'Ada berapa rekan kerja yang naik kendaraan ini?',
        cta: {
          cancel: 'Batalin',
          order: 'Lanjut booking'
        }
      }
    },
    trip_reasons: [
      {
        ind: 'Meeting dengan client',
        eng: 'Meeting with clients',
        vie: 'Gặp khách hàng'
      }
    ],
    trip_code_required: false,
    trip_reason_required: false,
    trip_description_required: false,
    ride_share_info_required: false
  },
  tripConfig: {
    logo_url: '',
    trip_code_feature_enabled: false,
    trip_code_required: false,
    trip_reason_required: false,
    trip_reasons: [],
    trip_description_required: false,
    ride_share_feature_enabled: false,
    ride_share_info_required: false
  },
  isLoading: false,
  errors: null
}

export const getTripConfig = createAsyncThunk(
  'tripDetailSetting/getTripConfig',
  async (_, { rejectWithValue }) => {
    try {
      return await getTripConfigAPI()
    } catch (error) {
      const { status, data } = error.response
      if (data || status) return rejectWithValue({ data, status })
      return error
    }
  },
  {
    condition: (_, { getState }) => {
      const { error } = getState() as {
        error: ErrorState
      }
      if (
        error.status === 429 &&
        Math.floor(Date.now() / 1000) <= error.updateAt!
      ) {
        return false
      }
    },
    dispatchConditionRejection: true
  }
)

export const tripDetailSettingSlice = createSlice({
  name: 'tripDetailSetting',
  initialState,
  reducers: {
    setMobilePreviewLanguage: (state, action) => {
      const { payload } = action
      state.mobilePreview.language = payload
    },
    setMobilePreviewTripReasons: (state, action) => {
      const { payload } = action
      state.mobilePreview.trip_reasons = payload?.length
        ? payload
        : initialState.mobilePreview.trip_reasons
    },
    setMobilePreviewTripCode: (state, action) => {
      state.mobilePreview.trip_code_required = action.payload
    },
    setMobilePreviewTripReason: (state, action) => {
      state.mobilePreview.trip_reason_required = action.payload
    },
    setMobilePreviewTripDescription: (state, action) => {
      state.mobilePreview.trip_description_required = action.payload
    },
    setMobilePreviewRideShare: (state, action) => {
      state.mobilePreview.ride_share_info_required = action.payload
    },
    setCompanyLogoUrl: (state, action) => {
      state.tripConfig.logo_url = action.payload
    },
    deleteMobilePreviewTripReasons: (state, action) => {
      state.mobilePreview.trip_reasons = state.mobilePreview.trip_reasons.filter(
        (_, id) => id !== action.payload
      )
    },
    insertOrUpdateMobilePreviewTripReasons: (state, action) => {
      const { payload } = action
      if (!payload) {
        state.mobilePreview.trip_reasons.push({
          ind: '',
          eng: '',
          vie: ''
        })
      } else {
        const { index, data } = payload
        const defaultReasonId =
          state.mobilePreview.trip_reasons[index].ind || ''
        const defaultReasonEn =
          state.mobilePreview.trip_reasons[index].eng || ''
        const defaultReasonVi =
          state.mobilePreview.trip_reasons[index].vie || ''
        state.mobilePreview.trip_reasons[index] = {
          ...state.mobilePreview.trip_reasons[index],
          ind: data?.ind ?? defaultReasonId,
          eng: data?.eng ?? defaultReasonEn,
          vie: data?.vie ?? defaultReasonVi
        }
      }
    },
    resetMobilePreview: (state) => {
      const {
        trip_code_required,
        trip_reason_required,
        trip_reasons
      } = state.tripConfig
      const treasons: TripReasonInputData[] = trip_reasons.map(
        (trip_reason: TripReasonData) => {
          const { id: ind = '', en: eng, vi: vie = '' } = trip_reason
          const showIdTranslation = (ind !== '').toString()
          const showViTranslation = (vie !== '').toString()
          return {
            eng,
            ind,
            vie,
            showIdTranslation,
            showViTranslation
          }
        }
      )
      state.mobilePreview = {
        ...state.mobilePreview,
        trip_code_required,
        trip_reason_required,
        trip_reasons: trip_reasons.length
          ? treasons
          : initialState.mobilePreview.trip_reasons
      }
    },
    resetState: () => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTripConfig.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getTripConfig.fulfilled, (state, action) => {
        state.isLoading = false
        state.errors = null
        const { trip_config } = action.payload.data
        state.tripConfig = trip_config
        state.tripConfig.trip_reasons = trip_config.trip_reasons || []
      })
      .addCase(
        getTripConfig.rejected,
        (state, action: PayloadAction<any, string, any, any>) => {
          state.isLoading = false
          state.errors = action.payload || {
            status: 500,
            data: `${action.error.name}: ${action.error.message}`
          }
        }
      )
  }
})

export const {
  deleteMobilePreviewTripReasons,
  insertOrUpdateMobilePreviewTripReasons,
  resetMobilePreview,
  setCompanyLogoUrl,
  setMobilePreviewLanguage,
  setMobilePreviewTripCode,
  setMobilePreviewTripReason,
  setMobilePreviewTripReasons,
  setMobilePreviewTripDescription,
  setMobilePreviewRideShare,
  resetState
} = tripDetailSettingSlice.actions

export const tripDetailSettingSelector = (state: RootState) =>
  state.tripDetailSetting

export default tripDetailSettingSlice.reducer
