import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { ErrorData } from 'features/error/errorSlice'
import { verifyToken, setPasswordUser } from 'utils/api'

interface SetPasswordUserPayload {
  password: string
  token: string
}

export interface VerifyState {
  email: string
  tokenStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
  tokenError: ErrorData
  passwordStatus: 'idle' | 'succeeded' | 'failed'
  passwordError: ErrorData
}

const initialState: VerifyState = {
  email: '',
  tokenStatus: 'idle',
  tokenError: null,
  passwordStatus: 'idle',
  passwordError: null
}

export const getVerifiedToken = createAsyncThunk(
  'verify/tokenUser',
  async (payload: string, { rejectWithValue }) => {
    try {
      const response = await verifyToken(payload)
      return response
    } catch (error) {
      const { status, data } = error.response
      return rejectWithValue({ data, status })
    }
  }
)

export const setTokenPassword = createAsyncThunk(
  'verify/passwordUser',
  async (payload: SetPasswordUserPayload, { rejectWithValue }) => {
    try {
      const response = await setPasswordUser(payload)
      return response
    } catch (error) {
      const { status, data } = error.response
      return rejectWithValue({ data, status })
    }
  }
)

const verifySlice = createSlice({
  name: 'verify',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getVerifiedToken.pending, (state) => {
        state.tokenStatus = 'loading'
      })
      .addCase(getVerifiedToken.fulfilled, (state, action) => {
        state.tokenStatus = 'succeeded'
        state.email = action.payload.data.email
      })
      .addCase(
        getVerifiedToken.rejected,
        (state, action: PayloadAction<any, string, any, any>) => {
          state.tokenStatus = 'failed'
          state.tokenError = action.payload
        }
      )
      .addCase(setTokenPassword.fulfilled, (state) => {
        state.passwordStatus = 'succeeded'
      })
      .addCase(
        setTokenPassword.rejected,
        (state, action: PayloadAction<any, string, any, any>) => {
          state.passwordStatus = 'failed'
          state.passwordError = action.payload
        }
      )
  }
})

export const verifyState = (state: RootState) => state.verify

export default verifySlice.reducer
