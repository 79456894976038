const xandar = {
  '--background-fill-secondary': 'hsla(214, 28%, 95%, 1)',
  '--background-fill-highlight': 'hsla(214, 18%, 92%, 1)',
  '--background-fill-inverse': 'hsla(231, 7%, 19%, 1)',
  '--background-border-solid-primary': 'solid hsla(220, 20%, 88%, 1)',
  '--background-border-solid-secondary': 'solid hsla(220, 22%, 92%, 1)',
  '--background-border-dotted-primary': 'dotted hsla(220, 22%, 92%, 1)',
  '--background-border-dotted-secondary': 'dotted hsla(220, 20%, 88%, 1)',

  '--text-primary': 'hsla(231, 7%, 19%, 1)',
  '--text-secondary': 'hsla(231, 7%, 21%, 1)',
  '--text-muted': 'hsla(233, 5%, 39%, 1)',
  '--text-inverse': 'hsla(0, 0%, 100%, 1)',
  '--text-disabled': 'hsla(224, 12%, 76%, 1)',

  '--icon-primary': 'hsla(231, 7%, 21%, 1)',
  '--icon-muted': 'hsla(233, 5%, 39%, 1)',
  '--icon-disabled': 'hsla(224, 12%, 76%, 1)',

  '--link-default': 'hsla(229, 79%, 51%, 1)',
  '--link-hover': 'hsla(229, 99%, 62%, 1)',
  '--link-active': 'hsla(229, 79%, 51%, 1)',
  '--link-visited': 'hsla(302, 53%, 52%, 1)',

  '--interactive-fill-hover': 'hsla(216, 33%, 97%, 1)',
  '--interactive-fill-active': 'hsla(214, 28%, 95%, 1)',
  '--interactive-fill-alternate': 'transparent',

  '--interactive-fill-selected-default': 'hsla(232, 7%, 21%, 1)',
  '--interactive-fill-selected-hover': 'hsla(233, 5%, 39%, 1)',
  '--interactive-fill-selected-active': 'hsla(231, 7%, 19%, 1)',

  '--interactive-disabled': 'hsla(214, 18%, 92%, 1)',
  '--interactive-focus': 'hsla(229, 99%, 62%, 1)',

  '--interactive-border-solid': 'solid hsla(220, 20%, 88%, 1)',
  '--interactive-border-dotted': 'dotted hsla(220, 20%, 88%, 1)',
  '--interactive-border-support': 'hsla(355, 82%, 60%, 1)',

  '--support-danger-default': 'hsla(351, 87%, 64%, 1)',
  '--support-danger-hover': 'hsla(350, 86%, 67%, 1)',
  '--support-danger-active': 'hsla(347, 64%, 47%, 1)',
  '--support-danger-fill': 'hsla(348, 94%, 94%, 1)',

  '--support-warning-default': 'hsla(44, 100%, 64%, 1)',
  '--support-warning-hover': 'hsla(44, 100%, 71%, 1)',
  '--support-warning-active': 'hsla(44, 80%, 59%, 1)',
  '--support-warning-fill': 'hsla(39, 82%, 91%, 1)',

  '--support-success-default': 'hsla(171, 100%, 42%, 1)',
  '--support-success-hover': 'hsla(166, 61%, 70%, 1)',
  '--support-success-active': 'hsla(177, 99%, 32%, 1)',
  '--support-success-fill': 'hsla(167, 61%, 88%, 1)',

  '--support-info-default': 'hsla(229, 99%, 62%, 1)',
  '--support-info-hover': 'hsla(225, 91%, 65%, 1)',
  '--support-info-active': 'hsla(229, 79%, 51%, 1)',
  '--support-info-fill': 'hsla(219, 74%, 95%, 1)',

  '--support-neutral-fill': 'hsla(214, 28%, 95%, 1)',

  '--overlay-black': 'hsla(232, 7%, 21%, 0.8)',

  '--shadow-low': '0px 2px 12px 0px hsla(235, 74%, 28%, 0.08)',
  '--shadow-high': '0px 12px 24px 0px hsla(229, 56%, 27%, 0.1)'
}

export const theme = {
  ...xandar,
  '--background-fill-brand': 'hsla(191, 100%, 42%, 1)',
  '--text-brand': 'hsla(191, 100%, 42%, 1)',
  '--icon-brand': 'hsla(191, 100%, 42%, 1)',
  '--interactive-fill-brand-default': 'hsla(191, 100%, 34%, 1)',
  '--interactive-fill-brand-hover': 'hsla(191, 100%, 42%, 1)',
  '--interactive-fill-brand-active': 'hsla(191, 72%, 54%, 1)',
  '--interactive-focus': 'hsla(219, 42%, 18%, 1)'
}
