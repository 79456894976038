import { combineReducers, configureStore, Reducer } from '@reduxjs/toolkit'
import authReducer, { doLogout } from 'features/auth/authSlice'
import verifyReducer from 'features/verify-user/verifySlice'
import homeReducer from 'features/home/homeSlice'
import groupReducer, {
  initialState as iStateGroup
} from 'features/groups/groupSlice'
import employeeTabsReducer, {
  initialState as iStateEmployeeTabs
} from 'features/employees/employeeTabsSlice'
import companyReducer, {
  initialState as iStateCompany
} from 'features/company/companySlice'
import employeeReducer, {
  initialState as iStateEmployee
} from 'features/employees/employeeSlice'
import policyTabsReducer, {
  initialState as iStatePolicyTabs
} from 'features/policies/policyTabsSlice'
import policyReducer, {
  initialState as iStatePolicy
} from 'features/policies/policySlice'
import ruleReducer, {
  initialState as iStateRule
} from 'features/rules/ruleSlice'
import voucherReducer, {
  initialState as iStateVoucher
} from 'features/vouchers/voucherSlice'
import voucherTabsReducer, {
  initialState as iStateVoucherTabs
} from 'features/vouchers/voucherTabsSlice'
import employeePolicyReducer, {
  initialState as iStateEmployeePolicy
} from 'features/policies/employeePolicySlice'
import employeeGroupReducer, {
  initialState as iStateEmployeeGroup
} from 'features/groups/employeeGroupSlice'
import notificationReducer, {
  initialState as iStateNotification
} from 'features/notification/notificationSlice'
import tripReducer, {
  initialState as iStateTrip
} from 'features/trips/tripSlice'
import bulkUploadEmployeeReducer, {
  initialState as iStateBulkUploadEmployee
} from 'features/employees/bulkUploadEmployeeSlice'
import profileReducer, { setCompanyId } from 'features/profile/profileSlice'
import settingTabsReducer, {
  initialState as iStateSettingTabs
} from 'features/settings/settingTabsSlice'
import tripDetailSettingReducer from 'features/settings/tripDetailSettingSlice'
import sftpDetailSettingReducer from 'features/settings/sftpDetailSettingSlice'
import helpCenterReducer, {
  initialState as iStateHelpCenter
} from 'features/helpcenter/helpCenterSlice'
import verifyEmployeeReducer from 'features/verify-employee/verifyEmployeeSlice'
import verifyIndividualEmployeeReducer from 'features/verify-individual-employee/verifyIndividualEmployeeSlice'
import errorReducer from 'features/error/errorSlice'
import paymentTabsReducer, {
  initialState as iStatePaymentTabs
} from 'features/payments/paymentTabsSlice'
import paymentMethodReducer, {
  initialState as iStatePaymentMethod
} from 'features/payments/paymentMethodSlice'

const combinedReducer = combineReducers({
  auth: authReducer,
  verify: verifyReducer,
  home: homeReducer,
  group: groupReducer,
  employeeTabs: employeeTabsReducer,
  employee: employeeReducer,
  policyTabs: policyTabsReducer,
  policy: policyReducer,
  rule: ruleReducer,
  employeePolicy: employeePolicyReducer,
  notification: notificationReducer,
  trip: tripReducer,
  bulkUploadEmployee: bulkUploadEmployeeReducer,
  employeeGroup: employeeGroupReducer,
  profile: profileReducer,
  company: companyReducer,
  voucher: voucherReducer,
  voucherTabs: voucherTabsReducer,
  settingTabs: settingTabsReducer,
  tripDetailSetting: tripDetailSettingReducer,
  sftpDetailSetting: sftpDetailSettingReducer,
  paymentTabs: paymentTabsReducer,
  paymentMethod: paymentMethodReducer,
  helpCenter: helpCenterReducer,
  verifyEmployee: verifyEmployeeReducer,
  verifyIndividualEmployee: verifyIndividualEmployeeReducer,
  error: errorReducer
})

const rootReducer: Reducer = (state, action) => {
  if (
    action.type === doLogout.fulfilled.type ||
    action.type === doLogout.rejected.type
  ) {
    state = undefined
  }
  if (
    action.type === setCompanyId.type &&
    action.payload !== state.profile.companyId
  ) {
    const prevState = combinedReducer(state, action)
    return {
      ...prevState,
      paymentTabs: iStatePaymentTabs,
      settingTabs: iStateSettingTabs,
      policyTabs: iStatePolicyTabs,
      employeeTabs: iStateEmployeeTabs,
      voucherTabs: iStateVoucherTabs,
      company: iStateCompany,
      paymentMethod: iStatePaymentMethod,
      bulkUploadEmployee: iStateBulkUploadEmployee,
      employee: iStateEmployee,
      voucher: iStateVoucher,
      group: {
        ...prevState.group,
        viewMode: iStateGroup.viewMode
      },
      policy: iStatePolicy,
      rule: iStateRule,
      trip: iStateTrip,
      employeeGroup: iStateEmployeeGroup,
      employeePolicy: iStateEmployeePolicy,
      notification: iStateNotification,
      helpCenter: {
        ...prevState.helpCenter,
        popup: iStateHelpCenter.popup
      }
    }
  }
  return combinedReducer(state, action)
}

const store = configureStore({
  reducer: rootReducer
})

export default store
export type RootState = ReturnType<typeof store.getState>
