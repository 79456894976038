/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/core'
import 'twin.macro'
import * as React from 'react'
import Button from '@asphalt-react/button'

import ScreenCenterLayout from 'components/Layout/ScreenCenterLayout'
import CSSReset from 'components/CSSReset'
import Img from 'components/Img'

interface ErrorBoundaryProps {
  children: React.ReactNode
  debug: boolean
  errorMessage: string
  render: void | null
}

class ErrorBoundary extends React.PureComponent<ErrorBoundaryProps> {
  static defaultProps: ErrorBoundaryProps = {
    children: null,
    debug: false,
    errorMessage: '',
    render: null
  }

  state = {
    hasError: false
  }

  componentDidCatch(error: Error, info: { componentStack: any }) {
    const { debug } = this.props

    if (debug) {
      console.groupCollapsed(`Error occured!`)
      console.error(`
        [ErrorBoundary] Error message: ${error.message}
        [ErrorBoundary] Error stack: ${error.stack}
        [ErrorBoundary] Component stack: ${info.componentStack}
      `)
      console.groupEnd()
    }

    this.setState({ hasError: true })
  }

  reloadPage = () => {
    window.location.reload()
  }

  defaultRender = () => {
    const message = this.props.errorMessage || 'Oops, something went wrong'

    return (
      <>
        <CSSReset />
        <ScreenCenterLayout>
          <div tw="flex flex-wrap items-center">
            <div tw="w-full p-8 lg:(w-1/2 p-12)">
              <Img
                width="624"
                height="319"
                src="/images/error/troubleshoot.png"
                alt="internal server error"
              />
            </div>
            <div tw="w-full mb-8 text-center box-border px-8 lg:(w-1/2 text-left px-12 mb-0)">
              <h3 tw="md:text-28 font-semibold w-full">{message}</h3>
              <p tw="text-sm md:text-lg w-full mb-4">
                Don’t worry, we’re fixing this. We’ll be back for you soon!
              </p>
              <Button outline size="m" onClick={this.reloadPage}>
                Try again
              </Button>
            </div>
          </div>
        </ScreenCenterLayout>
      </>
    )
  }

  render() {
    const { children, render } = this.props
    const renderError = render || this.defaultRender

    return this.state.hasError ? renderError() : children
  }
}

export default ErrorBoundary
