import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from 'app/store'
import configuration from 'configuration'
import { ErrorData, ErrorState } from 'features/error/errorSlice'
import { PolicyData, PolicyScope } from 'features/policies/policySlice'
import { getPolicyAPI, getServiceTypeAPI } from 'utils/api'
import { CountryCode } from 'utils/constant/country'
import policyType from 'utils/constant/policyType'
import { viewModeType } from 'utils/constant/viewMode'
import { ServiceTypesRuleList } from 'utils/servicetype'

export interface ServiceTypeData {
  id: number
  name: string
  icon: string
  description: string
}

export type POIIntent = 'ORIGIN' | 'DESTINATION'

export type TimeWindowDayForm = {
  days: string[]
}

export type TimeWindowDayData = {
  day: string[]
}

export type TimeWindowTimeData = {
  hour?: {
    start: string
    end: string
  }
}

export type TimeWindowData = TimeWindowDayData &
  TimeWindowTimeData & {
    is_all_day: boolean
  }

export type POIData = {
  intent: POIIntent[]
  name: string
  radius_in_meter: number
  max_distance_in_km?: number
  latitude: number
  longitude: number
  address: string
}

export type POIFormData = Pick<POIData, 'name' | 'address'> & {
  intent: string
  radius_in_meter: string
  max_distance_in_km: string
  latitude: string
  longitude: string
}

export interface RuleConfig {
  service_types?: ServiceTypesRuleList[]
  time_window?: TimeWindowData[]
  poi?: POIData[]
}

export interface RuleData extends Omit<PolicyData, 'policy_config'> {
  policy_config: RuleConfig
}

export interface RuleState {
  isLoading: boolean
  rules: RuleData[]
  total: number
  itemsPerPage: number
  currentPage: number
  form: {
    time_window: TimeWindowDayForm[]
    service_type_ids: Array<string>
  }
  service_type: {
    errors: ErrorData
    isLoading: boolean
    data: ServiceTypeData[]
  }
  viewMode: string
  errors: ErrorData
}

interface SetDaysActionPayload {
  index: number
  value: string
}

const allDays = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat']

export const initialState: RuleState = {
  isLoading: false,
  rules: [],
  total: 0,
  itemsPerPage: 10,
  currentPage: 1,
  form: {
    time_window: [
      {
        days: []
      }
    ],
    service_type_ids: []
  },
  service_type: {
    errors: null,
    isLoading: false,
    data: []
  },
  viewMode: viewModeType.IDLE,
  errors: null
}

export type RuleFeature = 'voucher_rules' | 'rules'

type GetServiceTypePayload = {
  countryCode: CountryCode
  feature?: RuleFeature
  scope: PolicyScope
}

export const getServiceTypes = createAsyncThunk(
  'rule/getServiceTypes',
  async (payload: GetServiceTypePayload, { rejectWithValue }) => {
    try {
      const { countryCode, scope } = payload
      return await getServiceTypeAPI({
        countryCode,
        scope
      })
    } catch (error) {
      const { status, data } = error.response
      if (data || status) return rejectWithValue({ data, status })
      return error
    }
  },
  {
    condition: (_, { getState }) => {
      const { error } = getState() as {
        error: ErrorState
      }
      if (
        error.status === 429 &&
        Math.floor(Date.now() / 1000) <= error.updateAt!
      ) {
        return false
      }
    },
    dispatchConditionRejection: true
  }
)

export const getRules = createAsyncThunk(
  'rule/getRules',
  async (payload: any, { rejectWithValue }) => {
    try {
      payload.type = policyType.REGULAR_RULES

      return await getPolicyAPI(payload)
    } catch (error) {
      const { status, data } = error.response
      if (data || status) return rejectWithValue({ data, status })
      return error
    }
  },
  {
    condition: (_, { getState }) => {
      const { error } = getState() as {
        error: ErrorState
      }
      if (
        error.status === 429 &&
        Math.floor(Date.now() / 1000) <= error.updateAt!
      ) {
        return false
      }
    },
    dispatchConditionRejection: true
  }
)

const getFilteredServiceTypes = (svcTypes: ServiceTypeData[]) =>
  svcTypes.filter(
    (svcType: ServiceTypeData) =>
      !configuration.VOUCHER_CODE_SERVICE_TYPE_ID_DISABLED.split(',').includes(
        svcType.id.toString()
      )
  )

const getFilteredServiceTypeIDs = (svcTypes: ServiceTypeData[]) =>
  svcTypes.reduce((acc: string[], svcType: ServiceTypeData) => {
    const svcTypeIdStringified = svcType.id.toString()
    if (
      !configuration.VOUCHER_CODE_SERVICE_TYPE_ID_DISABLED.split(',').includes(
        svcTypeIdStringified
      )
    )
      acc.push(svcTypeIdStringified)
    return acc
  }, [])

export const ruleSlice = createSlice({
  name: 'rule',
  initialState: initialState,
  reducers: {
    setServiceTypeIds: (state, action) => {
      state.form.service_type_ids = action.payload
    },
    setServiceTypeId: (state, action) => {
      const value = action.payload
      const { service_type_ids } = state.form
      const { data: stData } = state.service_type
      const isAllServiceTypeSelected = service_type_ids.length === stData.length
      if (value === 'all' && isAllServiceTypeSelected) {
        state.form.service_type_ids = initialState.form.service_type_ids
      } else if (value === 'all') {
        state.form.service_type_ids = stData.map((st) => st.id.toString())
      } else if (service_type_ids.includes(value) && isAllServiceTypeSelected) {
        state.form.service_type_ids = [value]
      } else if (service_type_ids.includes(value)) {
        state.form.service_type_ids = service_type_ids.filter(
          (id) => id !== value
        )
      } else {
        state.form.service_type_ids = [...service_type_ids, value]
      }
    },
    setTimeWindow: (state, action) => {
      state.form.time_window = action.payload
    },
    setDays: (
      state,
      action: {
        type: any
        payload: SetDaysActionPayload
      }
    ) => {
      const { index, value } = action.payload
      const { days } = state.form.time_window[index]

      const isAllDay = days.length === allDays.length
      const isPartOfDays = days.includes(value)

      if (value === 'all' && isAllDay) {
        state.form.time_window[index].days =
          initialState.form.time_window[0].days
      } else if (value === 'all' && !isAllDay) {
        state.form.time_window[index].days = allDays
      } else if (isPartOfDays && isAllDay) {
        state.form.time_window[index].days = [value]
      } else if (isPartOfDays && !isAllDay) {
        let filterDay = days.filter((day) => day !== value)
        state.form.time_window[index].days = filterDay
      } else {
        state.form.time_window[index].days = [
          ...state.form.time_window[index].days,
          value
        ]
      }
    },
    appendDays: (state) => {
      state.form.time_window.push(initialState.form.time_window[0])
    },
    removeDays: (state, action) => {
      state.form.time_window.splice(action.payload, 1)
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload
    },
    setItemsPerPage: (state, action) => {
      state.itemsPerPage = action.payload
    },
    resetPagination: (state) => {
      state.itemsPerPage = initialState.itemsPerPage
      state.currentPage = initialState.currentPage
    },
    resetDays: (state, action) => {
      state.form.time_window[action.payload].days =
        initialState.form.time_window[0].days
    },
    resetForm: (state, action) => {
      if (action?.payload?.feature === 'voucher_rules') {
        state.form.service_type_ids = getFilteredServiceTypeIDs(
          state.service_type.data
        )
        state.form.time_window = initialState.form.time_window
      } else {
        state.form = initialState.form
      }
    },
    setViewMode: (state, action) => {
      state.viewMode = action.payload || initialState.viewMode
    },
    resetState: () => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(getServiceTypes.pending, (state) => {
        state.service_type.isLoading = true
      })
      .addCase(getServiceTypes.fulfilled, (state, action) => {
        state.service_type.isLoading = false
        state.service_type.errors = null
        const serviceTypes = action?.payload?.data?.service_types || []
        if (action?.meta?.arg?.feature === 'voucher_rules') {
          state.service_type.data = getFilteredServiceTypes(serviceTypes)
          state.form.service_type_ids = getFilteredServiceTypeIDs(serviceTypes)
        } else {
          state.service_type.data = serviceTypes
        }
      })
      .addCase(
        getServiceTypes.rejected,
        (state, action: PayloadAction<any, string, any, any>) => {
          state.service_type.isLoading = false
          state.service_type.errors = action.payload || {
            status: 500,
            data: `${action.error.name}: ${action.error.message}`
          }
        }
      )
      .addCase(getRules.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getRules.fulfilled, (state, action) => {
        const { policies, total } = action.payload.data
        state.isLoading = false
        state.errors = null
        state.rules = policies
        state.total = total
        if (total === 0) {
          state.viewMode = viewModeType.ADD
        } else {
          state.viewMode = viewModeType.IDLE
        }
      })
      .addCase(
        getRules.rejected,
        (state, action: PayloadAction<any, string, any, any>) => {
          state.isLoading = false
          state.errors = action.payload || {
            status: 500,
            data: `${action.error.name}: ${action.error.message}`
          }
        }
      )
  }
})

export const {
  setServiceTypeId,
  setServiceTypeIds,
  setTimeWindow,
  setDays,
  appendDays,
  removeDays,
  resetState,
  setCurrentPage,
  setItemsPerPage,
  resetDays,
  resetForm,
  resetPagination,
  setViewMode
} = ruleSlice.actions

export const ruleSelector = (state: RootState) => state.rule

export const transportServiceTypesSelected = (state: RootState) => {
  const {
    service_type: { data },
    form: { service_type_ids }
  } = state.rule
  return data.filter((svcType: ServiceTypeData) =>
    service_type_ids.includes(svcType.id.toString())
  )
}

export default ruleSlice.reducer
