import { createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'

export type ErrorData = {
  status: null | number
  data: any
} | null

export interface ErrorState {
  status: null | number
  data: unknown
  updateAt: null | number
}

export const initialState: ErrorState = {
  status: null,
  data: null,
  updateAt: null
}

export const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    setStatus: (state, action) => {
      const { status, data } = action.payload || { ...initialState }
      state.status = status
      state.data = data
    },
    setTimestamp: (state, action) => {
      state.updateAt = action.payload
    }
  }
})

export const { setStatus, setTimestamp } = errorSlice.actions

export const errorStatusSelector = (state: RootState) => state.error
export default errorSlice.reducer
