export enum PolicyType {
  REGULAR = 'REGULAR',
  REGULAR_RULES = 'REGULAR_RULES',
  EMPLOYEE_DEFAULT = 'EMPLOYEE_DEFAULT',
  EMPLOYEE_DEFAULT_RULES = 'EMPLOYEE_DEFAULT_RULES'
}

const policyType = {
  REGULAR: PolicyType.REGULAR,
  REGULAR_RULES: PolicyType.REGULAR_RULES,
  EMPLOYEE_DEFAULT: PolicyType.EMPLOYEE_DEFAULT,
  EMPLOYEE_DEFAULT_RULES: PolicyType.EMPLOYEE_DEFAULT_RULES
}

export default policyType
