import React, { createContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '@asphalt-react/loader'

import { getUserProfile, profileSelector } from 'features/profile/profileSlice'
import GoCorp from 'components/SVG/GoCorpLogo'
import ScreenCenterLayout from 'components/Layout/ScreenCenterLayout'
import { isLoggedIn } from 'features/auth/authSlice'
import InternalServer from 'components/InternalServer'

type UserProfileProviderProps = {
  children: React.ReactNode
}

const UserProfileContext = createContext(null)

export function UserProfileProvider({ children }: UserProfileProviderProps) {
  const dispatch = useDispatch()
  const { isLoading, companies, errors } = useSelector(profileSelector)
  const authenticated = useSelector(isLoggedIn)

  useEffect(() => {
    if (authenticated) dispatch(getUserProfile())
  }, [authenticated, dispatch])

  if (isLoading) {
    return (
      <ScreenCenterLayout>
        <div style={{ textAlign: 'center' }}>
          <GoCorp size="l" />
          <div style={{ width: '100%', marginTop: '16px' }}>
            <Loader />
          </div>
        </div>
      </ScreenCenterLayout>
    )
  } else if (errors) {
    return (
      <ScreenCenterLayout>
        <InternalServer noBorder />
      </ScreenCenterLayout>
    )
  }

  return (
    <UserProfileContext.Provider value={null}>
      {(companies.length || !authenticated) && children}
    </UserProfileContext.Provider>
  )
}
