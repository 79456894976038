import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { ErrorData } from 'features/error/errorSlice'
import { verifyIndividualEmployeeTokenAPI } from 'utils/api'

export interface VerifyIndividualEmployeeState {
  tokenStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
  tokenError: ErrorData
}

const initialState: VerifyIndividualEmployeeState = {
  tokenStatus: 'idle',
  tokenError: null
}

export const getVerifyIndividualEmployeeToken = createAsyncThunk(
  'verifyIndividualEmployee/token',
  async (payload: string, { rejectWithValue }) => {
    try {
      return await verifyIndividualEmployeeTokenAPI(payload)
    } catch (error) {
      const { status, data } = error.response
      return rejectWithValue({ data, status })
    }
  }
)

const verifyIndividualEmployeeSlice = createSlice({
  name: 'verifyIndividualEmployee',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getVerifyIndividualEmployeeToken.pending, (state) => {
        state.tokenStatus = 'loading'
      })
      .addCase(getVerifyIndividualEmployeeToken.fulfilled, (state, action) => {
        state.tokenStatus = 'succeeded'
      })
      .addCase(
        getVerifyIndividualEmployeeToken.rejected,
        (state, action: PayloadAction<any, string, any, any>) => {
          state.tokenStatus = 'failed'
          state.tokenError = action.payload
        }
      )
  }
})

export const verifyIndividualEmployeeState = (state: RootState) =>
  state.verifyIndividualEmployee

export default verifyIndividualEmployeeSlice.reducer
