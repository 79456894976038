import React from 'react'
import ReactDOM from 'react-dom'
import { Provider as StateProvider } from 'react-redux'
import { I18nextProvider } from 'react-i18next'
import ThemeProvider from '@asphalt-react/theme-provider'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

import App from './App'
import store from 'app/store'
import i18n from './i18n'
import { theme } from './theme'
import { UserProfileProvider } from 'app/contexts/UserProfile'
import CSSReset from 'components/CSSReset'
import ErrorBoundary from 'components/ErrorBoundary'
import GoCorpHead from 'components/GoCorpHead'
import configuration from 'configuration'
import { version } from '../package.json'

// Start the mocking conditionally.
if (process.env.NODE_ENV === 'development') {
  const { worker } = require('./mocks/browser')
  worker.start()
}

if (configuration.ENV === 'production') {
  Sentry.init({
    dsn: configuration.SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    release: version,
    environment: 'production',
    tracesSampleRate: configuration.SENTRY_TRACESSAMPLERATE,
    allowUrls: [/:\/\/gocorp.gojek.com/],
    beforeSend(event) {
      // Ignore Acunetix-Deepscan
      if (window.navigator.userAgent.indexOf('Acunetix-Deepscan') !== -1)
        return null
      return event
    }
  })
}

ReactDOM.render(
  <ErrorBoundary debug={process.env.NODE_ENV === 'development'}>
    <StateProvider store={store}>
      <I18nextProvider i18n={i18n}>
        <ThemeProvider theme={theme}>
          <GoCorpHead />
          <CSSReset />
          <UserProfileProvider>
            <App />
          </UserProfileProvider>
        </ThemeProvider>
      </I18nextProvider>
    </StateProvider>
  </ErrorBoundary>,
  document.getElementById('root')
)
