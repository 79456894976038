import { createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'

export interface PaymentTabsState {
  active: '' | 'payments' | 'payments/method'
  isFormChanged: {
    paymentMethod: boolean
  }
}

export const initialState: PaymentTabsState = {
  active: '',
  isFormChanged: {
    paymentMethod: false
  }
}

export const paymentTabsSlice = createSlice({
  name: 'paymentTabs',
  initialState,
  reducers: {
    setActive: (state, action) => {
      state.active = action.payload
    },
    setIsFormChangedPaymentMethod: (state, action) => {
      state.isFormChanged.paymentMethod = action.payload
    },
    resetState: (state) => {
      state.isFormChanged = initialState.isFormChanged
    }
  }
})

export const {
  setActive,
  setIsFormChangedPaymentMethod,
  resetState
} = paymentTabsSlice.actions

export const paymentTabsSelector = (state: RootState) => state.paymentTabs

export default paymentTabsSlice.reducer
