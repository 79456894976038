import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

import i18n from 'i18n'
import { RootState } from 'app/store'
import { ErrorData, ErrorState, setStatus } from 'features/error/errorSlice'
import { doLogout } from 'features/auth/authSlice'
import { getUserProfileAPI } from 'utils/api'
import { getCompany, setCompany } from 'utils/company'
import type {
  CountryCode,
  PhoneCode,
  CurrencyCode,
  CurrencySymbol
} from 'utils/constant/country'

export interface CompanyData {
  id: number
  name: string
  country_code: CountryCode
  currency: {
    code: CurrencyCode
    symbol: CurrencySymbol
  }
  phone_code: PhoneCode
}

export interface ProfileState {
  companies: CompanyData[]
  errors: ErrorData
  isLoading: boolean
  companyId: null | number
}

const initialState: ProfileState = {
  companies: [],
  errors: null,
  isLoading: false,
  companyId: getCompany()
}

export const getUserProfile = createAsyncThunk(
  'profile/getProfile',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await getUserProfileAPI()
      const { companies } = response.data
      if (companies.length === 0) {
        dispatch({ type: doLogout.fulfilled.type })
        const err = {
          response: {
            status: 1001,
            data: {
              message: i18n.t('errors.no_companies')
            }
          }
        }
        throw err
      }
      return response
    } catch (error) {
      const { status, data } = error.response
      if (data || status) {
        dispatch(setStatus({ status, data }))
        return rejectWithValue({ data, status })
      }
      return error
    }
  },
  {
    condition: (_, { getState }) => {
      const { error } = getState() as {
        error: ErrorState
      }
      if (
        error.status === 429 &&
        Math.floor(Date.now() / 1000) <= error.updateAt!
      ) {
        return false
      }
    },
    dispatchConditionRejection: true
  }
)

export const profileSlice = createSlice({
  name: 'profile',
  initialState: initialState,
  reducers: {
    setCompanyId: (state, action) => {
      state.companyId = action.payload
      setCompany(action.payload)
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserProfile.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getUserProfile.fulfilled, (state, action) => {
        const { companies } = action.payload.data
        state.isLoading = false
        state.errors = null
        state.companies = companies
        const findCompany = companies.find(
          (company: CompanyData) => company.id === getCompany()
        )
        if (!findCompany) {
          const companyId = companies[0].id
          setCompany(companyId)
          state.companyId = companyId
        }
      })
      .addCase(
        getUserProfile.rejected,
        (state, action: PayloadAction<any, string, any, any>) => {
          state.isLoading = false
          state.errors = action.payload || {
            status: 500,
            data: `${action.error.name}: ${action.error.message}`
          }
        }
      )
  }
})

export const { setCompanyId } = profileSlice.actions

export const profileSelector = (state: RootState) => state.profile

export const companySelector = (state: RootState) => {
  return state.profile.companies.find(
    (company: CompanyData) => company.id === state.profile.companyId
  )
}

export default profileSlice.reducer
