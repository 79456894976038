import { createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'

export interface SettingTabsState {
  active: '' | 'settings' | 'settings/sftp' | 'settings/transport'
  isFormChanged: {
    trip: boolean
    sftp: boolean
    paymentMethod: boolean
  }
  mobilePreview: 'TRIP_CONFIG' | 'BUSINESS_PROFILE' | 'PAYMENT_METHOD'
}

export const initialState: SettingTabsState = {
  active: '',
  isFormChanged: {
    trip: false,
    sftp: false,
    paymentMethod: false
  },
  mobilePreview: 'TRIP_CONFIG'
}

export const settingTabsSlice = createSlice({
  name: 'settingTabs',
  initialState: initialState,
  reducers: {
    setActive: (state, action) => {
      state.active = action.payload
      switch (state.active) {
        case 'settings':
          state.mobilePreview = 'BUSINESS_PROFILE'
          break
        default:
          state.mobilePreview = 'TRIP_CONFIG'
          break
      }
    },
    setMobilePreview: (state, action) => {
      state.mobilePreview = action.payload
    },
    setIsFormChangedTripDetail: (state, action) => {
      state.isFormChanged.trip = action.payload
    },
    setIsFormChangedSFTPDetail: (state, action) => {
      state.isFormChanged.sftp = action.payload
    },
    setIsFormChangedPaymentMethod: (state, action) => {
      state.isFormChanged.paymentMethod = action.payload
    },
    resetState: (state) => {
      state.isFormChanged = initialState.isFormChanged
    }
  }
})

export const {
  setActive,
  setIsFormChangedTripDetail,
  setIsFormChangedPaymentMethod,
  setIsFormChangedSFTPDetail,
  setMobilePreview,
  resetState
} = settingTabsSlice.actions

export const settingTabsSelector = (state: RootState) => state.settingTabs

export default settingTabsSlice.reducer
