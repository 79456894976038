import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  EntityState,
  PayloadAction
} from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { ErrorData, ErrorState } from 'features/error/errorSlice'
import { getNotificationCountAPI, getNotificationListAPI } from 'utils/api'

const notificationAdapter = createEntityAdapter<NotificationData>({
  sortComparer: (notifA, notifB) => -notifA.date.localeCompare(notifB.date)
})

export type CSVErrorMetadata = {
  total_employee: number
  total_failed_employee: number
  employees: string[]
}

type NotificationCTADownloadType = 'DOWNLOAD'
type NotificationCTARedirectType = 'REDIRECT'

export type NotificationCTAData = {
  label: string
  link: string
  type: NotificationCTARedirectType | NotificationCTADownloadType
}

export interface NotificationData {
  type: string
  id: string
  date: string
  title: string
  description: string
  read: boolean
  cta?: NotificationCTAData
  data: CSVErrorMetadata
}

export interface NotificationState {
  isHighlight: boolean
  notifications: EntityState<NotificationData>
  errors: ErrorData
  isLoading: boolean
  total: number
  itemsPerPage: number
  currentPage: number
}

export const initialState: NotificationState = {
  isHighlight: false,
  notifications: notificationAdapter.getInitialState(),
  errors: null,
  isLoading: false,
  total: 0,
  itemsPerPage: 5,
  currentPage: 1
}

export const getNotificationList = createAsyncThunk(
  'notification/list',
  async (_, { rejectWithValue, getState }) => {
    try {
      const {
        currentPage,
        itemsPerPage
      } = (getState() as RootState).notification
      return await getNotificationListAPI({
        limit: currentPage * itemsPerPage
      })
    } catch (error) {
      const { status, data } = error.response
      if (data || status) return rejectWithValue({ data, status })
      return error
    }
  },
  {
    condition: (_, { getState }) => {
      const { error } = getState() as {
        error: ErrorState
      }
      if (
        error.status === 429 &&
        Math.floor(Date.now() / 1000) <= error.updateAt!
      ) {
        return false
      }
    },
    dispatchConditionRejection: true
  }
)

export const getNotificationCount = createAsyncThunk(
  'notification/count',
  async (_, { rejectWithValue }) => {
    try {
      return await getNotificationCountAPI()
    } catch (error) {
      const { status, data } = error.response
      if (data || status) return rejectWithValue({ data, status })
      return error
    }
  },
  {
    condition: (_, { getState }) => {
      const { error } = getState() as {
        error: ErrorState
      }
      if (
        error.status === 429 &&
        Math.floor(Date.now() / 1000) <= error.updateAt!
      ) {
        return false
      }
    },
    dispatchConditionRejection: true
  }
)

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    removeHighlight: (state) => {
      state.isHighlight = false
    },
    setHighlight: (state) => {
      state.isHighlight = true
    },
    markAsRead: (state, action) => {
      notificationAdapter.updateMany(state.notifications, action.payload)
    },
    resetState: () => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNotificationList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getNotificationList.fulfilled, (state, action) => {
        state.isLoading = false
        state.errors = null
        const { notifications, total } = action.payload.data
        state.total = total
        notificationAdapter.upsertMany(state.notifications, notifications)
        if (total >= state.notifications.ids.length) state.currentPage += 1
      })
      .addCase(
        getNotificationList.rejected,
        (state, action: PayloadAction<any, string, any, any>) => {
          state.isLoading = false
          state.errors = action.payload || {
            status: 500,
            data: `${action.error.name}: ${action.error.message}`
          }
        }
      )
      .addCase(getNotificationCount.fulfilled, (state, action) => {
        const { notification_count } = action.payload.data
        state.isHighlight = notification_count > 0
      })
      .addCase(getNotificationCount.rejected, (state) => {
        state.isHighlight = false
      })
  }
})

export const {
  removeHighlight,
  setHighlight,
  markAsRead,
  resetState
} = notificationSlice.actions
export const notificationStatusSelector = (state: RootState) =>
  state.notification
export const notificationSelector = notificationAdapter.getSelectors(
  (state: RootState) => state.notification.notifications
)
export default notificationSlice.reducer
