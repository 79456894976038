enum Selection {
  ALL = 'ALL',
  SELECTED = 'SELECTED',
  UNSELECTED = 'UNSELECTED'
}

export enum EmployeeStatus {
  ALL = 'all',
  CONNECTED = 'connected',
  WAITING_VERIFICATION = 'waiting_verification'
}

export enum VoucherStatus {
  ALL = 'ALL',
  EXPIRED = 'EXPIRED',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}

export enum GroupStatus {
  ACTIVE = 'active',
  DELETED = 'deleted'
}

export enum PolicyStatus {
  EXPIRED = 'EXPIRED',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}

export const SelectionType = {
  ALL: Selection.ALL,
  SELECTED: Selection.SELECTED,
  UNSELECTED: Selection.UNSELECTED
}

export const EmployeeStatusType = {
  ALL: EmployeeStatus.ALL,
  CONNECTED: EmployeeStatus.CONNECTED,
  WAITING_VERIFICATION: EmployeeStatus.WAITING_VERIFICATION
}

export const VoucherStatusType = {
  ALL: VoucherStatus.ALL,
  EXPIRED: VoucherStatus.EXPIRED,
  ACTIVE: VoucherStatus.ACTIVE,
  INACTIVE: VoucherStatus.INACTIVE
}

export const GroupStatusType = {
  DELETED: GroupStatus.DELETED,
  ACTIVE: GroupStatus.ACTIVE
}

export const PolicyStatusType = {
  EXPIRED: PolicyStatus.EXPIRED,
  ACTIVE: PolicyStatus.ACTIVE,
  INACTIVE: PolicyStatus.INACTIVE
}

export const NO_GROUP_ID: number = 0
