import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  PayloadAction,
  EntityState
} from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { ErrorData, ErrorState } from 'features/error/errorSlice'

import { getGroupsAPI } from 'utils/api'
import { GroupStatus } from 'utils/constant/filterStatus'
import { viewModeType } from 'utils/constant/viewMode'
import { escapeString } from 'utils/escapestring'

export interface GroupData {
  company_id?: number
  group_id: number
  name: string
  status?: GroupStatus
  total_employee: number
}

export interface GroupState {
  isLoading: boolean
  groups: EntityState<GroupData>
  viewMode: string
  errors: ErrorData
}

const groupsAdapter = createEntityAdapter<GroupData>({
  selectId: (group) => group.group_id,
  sortComparer: (groupA, groupB) =>
    groupA.name.toLowerCase().localeCompare(groupB.name.toLowerCase())
})

export const initialState: GroupState = {
  isLoading: false,
  groups: groupsAdapter.getInitialState(),
  viewMode: viewModeType.IDLE,
  errors: null
}

export const getGroups = createAsyncThunk(
  'group/getGroups',
  async (_, { rejectWithValue }) => {
    try {
      const response = await getGroupsAPI()
      return response
    } catch (error) {
      const { status, data } = error.response
      if (data || status) return rejectWithValue({ data, status })
      return error
    }
  },
  {
    condition: (_, { getState }) => {
      const { error } = getState() as {
        error: ErrorState
      }
      if (
        error.status === 429 &&
        Math.floor(Date.now() / 1000) <= error.updateAt!
      ) {
        return false
      }
    },
    dispatchConditionRejection: true
  }
)

export const groupSlice = createSlice({
  name: 'group',
  initialState: initialState,
  reducers: {
    updateGroup: (state, action) => {
      const { group_id: id } = action.payload
      const {
        name: previousName,
        total_employee: previousTotalEmployee
      } = state.groups.entities[id] as GroupData
      const {
        name = previousName,
        employee_added_count = 0,
        employee_removed_count = 0
      } = action.payload
      groupsAdapter.updateOne(state.groups, {
        id,
        changes: {
          name,
          total_employee:
            previousTotalEmployee +
            (employee_added_count - employee_removed_count)
        }
      })
    },
    deleteGroup: (state, action) => {
      groupsAdapter.removeOne(state.groups, action.payload.group_id)
    },
    addGroup: (state, action) => {
      groupsAdapter.addOne(state.groups, action.payload)
    },
    setViewMode: (state, action) => {
      const { payload } = action
      state.viewMode = payload || initialState.viewMode
    },
    resetState: (state) => {
      if (state.groups.ids.length === 0) state.viewMode = viewModeType.ADD
      else state.viewMode = initialState.viewMode
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getGroups.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getGroups.fulfilled, (state, action) => {
        const { data } = action.payload
        state.isLoading = false
        state.errors = null
        if (data.length === 0) {
          state.viewMode = viewModeType.ADD
        } else {
          state.viewMode = viewModeType.IDLE
        }
        const formattedData = data.map((group: GroupData) => ({
          ...group,
          name: escapeString(group.name)
        }))
        groupsAdapter.setAll(state.groups, formattedData)
      })
      .addCase(
        getGroups.rejected,
        (state, action: PayloadAction<any, string, any, any>) => {
          state.isLoading = false
          state.errors = action.payload || {
            status: 500,
            data: `${action.error.name}: ${action.error.message}`
          }
        }
      )
  }
})

export const {
  updateGroup,
  deleteGroup,
  addGroup,
  setViewMode,
  resetState
} = groupSlice.actions

export const groupsSelector = groupsAdapter.getSelectors(
  (state: RootState) => state.group.groups
)
export const groupStatusSelector = (state: RootState) => state.group

export default groupSlice.reducer
