import { createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'

import { BillingType } from 'utils/constant/country'

export type PaymentMethodViewMode = '' | BillingType

export interface PaymentMethodState {
  showPaymentReminder: boolean
  wantReplaceCC: boolean
  viewMode: PaymentMethodViewMode
}

export const initialState: PaymentMethodState = {
  showPaymentReminder: true,
  wantReplaceCC: false,
  viewMode: ''
}

export const paymentMethodSlice = createSlice({
  name: 'paymentMethod',
  initialState,
  reducers: {
    setShowPaymentReminder: (state, action) => {
      state.showPaymentReminder = action.payload
    },
    setWantReplaceCC: (state, action) => {
      state.wantReplaceCC = action.payload
    },
    setViewMode: (state, action) => {
      state.viewMode = action.payload
    },
    resetState: (state) => {
      state.viewMode = initialState.viewMode
      state.wantReplaceCC = initialState.wantReplaceCC
    }
  }
})

export const {
  setViewMode,
  setShowPaymentReminder,
  setWantReplaceCC,
  resetState
} = paymentMethodSlice.actions

export const paymentMethodSelector = (state: RootState) => state.paymentMethod

export default paymentMethodSlice.reducer
