import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from 'app/store'
import { ErrorData, ErrorState } from 'features/error/errorSlice'
import { getSFTPDetailAPI } from 'utils/api'

interface SFTPConfigData {
  user_name: string
  pic_emails: string[]
  bucket_path: string
  sftp_host: string
  keys_count: number
}

export interface KeyPayloadData {
  key: string
}

export interface SFTPConfigPayloadData {
  pic_emails: string[]
  keys?: KeyPayloadData[]
}

export interface SFTPDetailSettingState {
  sftpConfig: SFTPConfigData
  isLoading: boolean
  errors: ErrorData
}

export interface SFTPData {
  value: string
}

export const initialState: SFTPDetailSettingState = {
  sftpConfig: {
    user_name: '',
    pic_emails: [],
    bucket_path: '',
    sftp_host: '',
    keys_count: 0
  },
  errors: null,
  isLoading: false
}

export const getSFTPDetail = createAsyncThunk(
  'sftpDetailSetting/getSFTPDetail',
  async (_, { rejectWithValue }) => {
    try {
      return await getSFTPDetailAPI()
    } catch (error) {
      const { status, data } = error.response
      if (data || status) return rejectWithValue({ data, status })
      return error
    }
  },
  {
    condition: (_, { getState }) => {
      const { error } = getState() as {
        error: ErrorState
      }
      if (
        error.status === 429 &&
        Math.floor(Date.now() / 1000) <= error.updateAt!
      ) {
        return false
      }
    },
    dispatchConditionRejection: true
  }
)

export const sftpDetailSettingSlice = createSlice({
  name: 'sftpDetailSetting',
  initialState,
  reducers: {
    resetState: () => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSFTPDetail.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getSFTPDetail.fulfilled, (state, action) => {
        state.isLoading = false
        state.errors = null
        const { id, ...sftpConfig } = action.payload.data
        state.sftpConfig = sftpConfig
      })
      .addCase(
        getSFTPDetail.rejected,
        (state, action: PayloadAction<any, string, any, any>) => {
          state.isLoading = false
          state.sftpConfig = initialState.sftpConfig
          state.errors = action.payload || {
            status: 500,
            data: `${action.error.name}: ${action.error.message}`
          }
        }
      )
  }
})

export const { resetState } = sftpDetailSettingSlice.actions

export const sftpDetailSettingSelector = (state: RootState) =>
  state.sftpDetailSetting

export default sftpDetailSettingSlice.reducer
