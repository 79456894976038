import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

declare global {
  interface Window {
    gtag: any
  }
}

export function usePageviews() {
  const location = useLocation()
  const sendEvent = useSendEvent()
  useEffect(() => {
    sendEvent('page_view', {
      page_title: document.title,
      page_location: window.location.href,
      page_path: location.pathname
    })
  }, [location, sendEvent])
}

export function useSendEvent() {
  return (eventName: string, eventDetail: object) => {
    if (typeof window.gtag === 'function') {
      window.gtag('event', eventName, eventDetail)
    }
  }
}
