/** @jsx jsx */
import { jsx } from '@emotion/core'
import 'twin.macro'

export default function Img({ src, alt, ...props }: any) {
  const regex = /\.(?:jpg|png)/g
  const isSupported = new RegExp(regex).test(src)
  const supportedImageOptimFormat: Array<'webp' | 'avif'> = [
    /* 'webp' */
  ]
  if (
    process.env.NODE_ENV === 'development' &&
    supportedImageOptimFormat.length
  )
    console.warn(
      "if there's new image assets and you get blank image, please do `npm run pc:imageoptim` "
    )
  const replaceFormatTo = (target: string) => src.replace(regex, '.' + target)
  return (
    <picture>
      {isSupported &&
        supportedImageOptimFormat.map((imgFormat, id) => (
          <source
            key={'source-' + id}
            type={'image/' + imgFormat}
            srcSet={replaceFormatTo(imgFormat)}
          />
        ))}
      <img {...props} alt={alt} src={src} />
    </picture>
  )
}
