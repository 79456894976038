import { createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'

interface BulkUploadEmployeeState {
  uploadPercentage: number
}

export const initialState: BulkUploadEmployeeState = {
  uploadPercentage: 0
}

export const bulkUploadEmployeeSlice = createSlice({
  name: 'bulkUploadEmployee',
  initialState: initialState,
  reducers: {
    updateUploadPercentage: (state, action) => {
      state.uploadPercentage = action.payload
    },
    resetState: () => initialState
  }
})

export const {
  updateUploadPercentage,
  resetState
} = bulkUploadEmployeeSlice.actions

export const bulkUploadEmployeeSelector = (state: RootState) =>
  state.bulkUploadEmployee

export default bulkUploadEmployeeSlice.reducer
