import * as React from 'react'
import { Global, css } from '@emotion/core'
import { theme as twTheme } from 'twin.macro'
import { theme } from 'theme'

export default function CSSReset() {
  return (
    <Global
      styles={css`
        html {
          line-height: 1.15;
          -webkit-text-size-adjust: 100%;
        }
        body {
          margin: 0;
          color: ${theme['--text-primary']};
        }
        main {
          display: block;
        }
        h1 {
          font-size: 2em;
          margin: 0.67em 0;
        }
        hr {
          box-sizing: content-box;
          height: 0;
          overflow: visible;
        }
        pre {
          font-family: monospace, monospace;
          font-size: 1em;
        }
        a {
          background-color: transparent;
        }
        abbr[title] {
          border-bottom: none;
          text-decoration: underline;
          -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
        }
        b,
        strong {
          font-weight: bolder;
        }
        code,
        kbd,
        samp {
          font-family: monospace, monospace;
          font-size: 1em;
        }
        small {
          font-size: 80%;
        }
        sub,
        sup {
          font-size: 75%;
          line-height: 0;
          position: relative;
          vertical-align: baseline;
        }
        sub {
          bottom: -0.25em;
        }
        sup {
          top: -0.5em;
        }
        img {
          border-style: none;
        }
        img:not([src]):not([srcset]),
        img[src=''] {
          visibility: hidden;
        }
        @-moz-document url-prefix() {
          img:-moz-loading {
            visibility: hidden;
          }
        }
        button,
        input,
        optgroup,
        select,
        textarea {
          font-family: inherit;
          font-size: 100%;
          line-height: 1.15;
          margin: 0;
        }
        button,
        input {
          overflow: visible;
        }
        button,
        select {
          text-transform: none;
        }
        [type='button'],
        [type='reset'],
        [type='submit'],
        button {
          -webkit-appearance: button;
        }
        [type='button']::-moz-focus-inner,
        [type='reset']::-moz-focus-inner,
        [type='submit']::-moz-focus-inner,
        button::-moz-focus-inner {
          border-style: none;
          padding: 0;
        }
        [type='button']:-moz-focusring,
        [type='reset']:-moz-focusring,
        [type='submit']:-moz-focusring,
        button:-moz-focusring {
          outline: 1px dotted ButtonText;
        }
        fieldset {
          padding: 0.35em 0.75em 0.625em;
        }
        legend {
          box-sizing: border-box;
          color: inherit;
          display: table;
          max-width: 100%;
          padding: 0;
          white-space: normal;
        }
        progress {
          vertical-align: baseline;
        }
        textarea {
          overflow: auto;
        }
        [type='checkbox'],
        [type='radio'] {
          box-sizing: border-box;
          padding: 0;
        }
        [type='number']::-webkit-inner-spin-button,
        [type='number']::-webkit-outer-spin-button {
          height: auto;
        }
        [type='search'] {
          -webkit-appearance: textfield;
          outline-offset: -2px;
        }
        [type='search']::-webkit-search-decoration {
          -webkit-appearance: none;
        }
        ::-webkit-file-upload-button {
          -webkit-appearance: button;
          font: inherit;
        }
        details {
          display: block;
        }
        summary {
          display: list-item;
        }
        template {
          display: none;
        }
        [hidden] {
          display: none;
        }
        blockquote,
        dd,
        dl,
        figure,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        hr,
        p,
        pre {
          margin: 0;
        }
        button {
          background-color: transparent;
          background-image: none;
        }
        button:focus {
          outline: 1px dotted;
          outline: 5px auto -webkit-focus-ring-color;
        }
        fieldset {
          margin: 0;
          padding: 0;
        }
        ol,
        ul {
          list-style: none;
          margin: 0;
          padding: 0;
        }
        html {
          font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
            Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
            'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
            'Noto Color Emoji';
          line-height: 1.5;
        }
        *,
        ::after,
        ::before {
          box-sizing: border-box;
          border-width: 0;
          border-style: solid;
          border-color: #e2e8f0;
        }
        hr {
          border-top-width: 1px;
        }
        img {
          border-style: solid;
        }
        textarea {
          resize: vertical;
        }
        input:-ms-input-placeholder,
        textarea:-ms-input-placeholder {
          color: ${twTheme`colors.gocorp.gray90`};
        }
        input::-ms-input-placeholder,
        textarea::-ms-input-placeholder {
          color: ${twTheme`colors.gocorp.gray90`};
        }
        input::placeholder,
        textarea::placeholder {
          color: ${twTheme`colors.gocorp.gray90`};
        }
        input[class*='Textfield__'] {
          --text-muted: ${twTheme`colors.gocorp.gray90`};
        }
        input[class*='inputField__'] {
          --text-muted: ${twTheme`colors.gocorp.gray90`};
        }
        li > a[role='menuitem'] {
          cursor: pointer;
        }
        [role='button'],
        button {
          cursor: pointer;
        }
        table {
          border-collapse: collapse;
        }
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          font-size: inherit;
          font-weight: inherit;
        }
        a {
          color: inherit;
          text-decoration: inherit;
        }
        button,
        input,
        optgroup,
        select,
        textarea {
          padding: 0;
          line-height: inherit;
          color: inherit;
        }
        code,
        kbd,
        pre,
        samp {
          font-family: Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New',
            monospace;
        }
        audio,
        canvas,
        embed,
        iframe,
        img,
        object,
        svg,
        video {
          display: block;
          vertical-align: middle;
        }
        img,
        video {
          max-width: 100%;
          height: auto;
        }

        @font-face {
          font-family: 'Maison Neue';
          font-weight: 400;
          font-style: normal;
          font-display: swap;
          src: url('/fonts/v1/MaisonNeue-Book.woff2') format('woff2'),
            url('/fonts/v1/MaisonNeue-Book.woff') format('woff'),
            url('/fonts/v1/MaisonNeue-Book.otf') format('opentype');
        }

        @font-face {
          font-family: 'Maison Neue';
          font-display: swap;
          font-weight: 400;
          font-style: italic;
          src: url('/fonts/v1/MaisonNeue-BookItalic.woff2') format('woff2'),
            url('/fonts/v1/MaisonNeue-BookItalic.woff') format('woff'),
            url('/fonts/v1/MaisonNeue-BookItalic.otf') format('opentype');
        }

        @font-face {
          font-family: 'Maison Neue';
          font-display: swap;
          font-weight: 600;
          font-style: normal;
          src: url('/fonts/v1/MaisonNeue-Demi.woff2') format('woff2'),
            url('/fonts/v1/MaisonNeue-Demi.woff') format('woff'),
            url('/fonts/v1/MaisonNeue-Demi.otf') format('opentype');
        }

        @font-face {
          font-family: 'Maison Neue';
          font-display: swap;
          font-weight: 600;
          font-style: italic;
          src: url('/fonts/v1/MaisonNeue-DemiItalic.woff2') format('woff2'),
            url('/fonts/v1/MaisonNeue-DemiItalic.woff') format('woff'),
            url('/fonts/v1/MaisonNeue-DemiItalic.otf') format('opentype');
        }

        @font-face {
          font-family: 'Maison Neue';
          font-display: swap;
          font-weight: 700;
          src: url('/fonts/v1/MaisonNeue-Bold.woff2') format('woff2'),
            url('/fonts/v1/MaisonNeue-Bold.woff') format('woff'),
            url('/fonts/v1/MaisonNeue-Bold.otf') format('opentype');
        }

        @font-face {
          font-family: 'Maison Neue Extended';
          font-display: swap;
          font-weight: 700;
          font-style: normal;
          src: url('/fonts/v1/MaisonNeueExtended-Bold.woff2') format('woff2'),
            url('/fonts/v1/MaisonNeueExtended-Bold.woff') format('woff'),
            url('/fonts/v1/MaisonNeueExtended-Bold.otf') format('opentype');
        }

        body {
          margin: 0;
          font-family: 'Maison Neue', sans-serif;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }

        code {
          font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
            monospace;
        }

        @keyframes 'slide-in' {
          from {
            opacity: 0;
            transform: scaleY(0.425);
            transform-origin: top;
          }
          to {
            transform-origin: top;
            opacity: 1;
            transform: scaleY(1);
          }
        }

        @keyframes 'fade-in' {
          from {
            opacity: 0;
            transform: scale(0.95);
          }
          to {
            opacity: 1;
            transform: scale(1);
          }
        }

        @keyframes 'fade-out' {
          from {
            opacity: 1;
          }
          to {
            opacity: 0;
          }
        }

        @keyframes ping {
          0% {
            transform: scale(1);
            opacity: 1;
          }
          75%,
          100% {
            transform: scale(2);
            opacity: 0;
          }
        }

        @keyframes pulse {
          0%,
          100% {
            opacity: 1;
          }
          50% {
            opacity: 0.5;
          }
        }
      `}
    />
  )
}
