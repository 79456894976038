import { createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'

export interface EmployeeTabsState {
  active: '' | 'employees' | 'groups'
  isFormChanged: {
    addEmployee: boolean
    editEmployee: boolean
    listEmployee: boolean
    addGroup: boolean
    editGroup: boolean
  }
}

export const initialState: EmployeeTabsState = {
  active: '',
  isFormChanged: {
    addEmployee: false,
    editEmployee: false,
    listEmployee: false,
    addGroup: false,
    editGroup: false
  }
}

export const employeeTabsSlice = createSlice({
  name: 'employeeTabs',
  initialState: initialState,
  reducers: {
    setActive: (state, action) => {
      state.active = action.payload
    },
    setIsFormChangedAddEmployee: (state, action) => {
      state.isFormChanged.addEmployee = action.payload
    },
    setIsFormChangedEditEmployee: (state, action) => {
      state.isFormChanged.editEmployee = action.payload
    },
    setIsFormChangedListEmployee: (state, action) => {
      state.isFormChanged.listEmployee = action.payload
    },
    setIsFormChangedAddGroup: (state, action) => {
      state.isFormChanged.addGroup = action.payload
    },
    setIsFormChangedEditGroup: (state, action) => {
      state.isFormChanged.editGroup = action.payload
    },
    resetState: (state) => {
      state.isFormChanged = initialState.isFormChanged
    }
  }
})

export const {
  setActive,
  setIsFormChangedAddEmployee,
  setIsFormChangedEditEmployee,
  setIsFormChangedListEmployee,
  setIsFormChangedAddGroup,
  setIsFormChangedEditGroup,
  resetState
} = employeeTabsSlice.actions

export const employeeTabsSelector = (state: RootState) => state.employeeTabs

export default employeeTabsSlice.reducer
