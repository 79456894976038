import React from 'react'
import { Helmet } from 'react-helmet'

type GoCorpHeadProps = {
  children?: React.ReactNode
}

const GoCorpHead = ({ children }: GoCorpHeadProps) => {
  return (
    <Helmet
      defaultTitle="Home - GoCorp Web Portal"
      titleTemplate="%s - GoCorp Web Portal"
    >
      <link
        rel="canonical"
        href={
          window.location.protocol +
          '//' +
          window.location.host +
          window.location.pathname
        }
      />
      {children}
    </Helmet>
  )
}

export default GoCorpHead
