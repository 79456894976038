import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  authSelector,
  doLogin,
  doLogout,
  isLoggedIn
} from 'features/auth/authSlice'
import { getProfile, getToken } from 'utils/auth'
import { setCompanyId } from 'features/profile/profileSlice'
import { getCompany } from 'utils/company'

export function useSessionSync() {
  const dispatch = useDispatch()
  const authenticated = useSelector(isLoggedIn)
  const { profile } = useSelector(authSelector)

  useEffect(() => {
    const onFocus = () => {
      if (JSON.stringify(profile) === JSON.stringify(getProfile())) {
        if (!authenticated && profile !== null && getProfile() !== null) {
          dispatch({ type: doLogout.fulfilled.type })
        } else {
          return
        }
      } else {
        if (authenticated && profile === null) {
          dispatch({
            type: setCompanyId.type,
            payload: getCompany()
          })
          dispatch({
            type: doLogin.fulfilled.type,
            payload: { data: { user_profile: getProfile(), token: getToken() } }
          })
        } else {
          dispatch({ type: doLogout.fulfilled.type })
        }
      }
    }
    window.addEventListener('focus', onFocus)
    return () => {
      window.removeEventListener('focus', onFocus)
    }
  }, [profile, dispatch, authenticated])
}
