import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { GetListAPIPayload } from 'features/employees/employeeSlice'
import { ErrorData, ErrorState } from 'features/error/errorSlice'
import { getPolicyAPI } from 'utils/api'
import { CurrencyCode } from 'utils/constant/country'
import { PolicyStatus } from 'utils/constant/filterStatus'
import { PolicyType } from 'utils/constant/policyType'
import { viewModeType } from 'utils/constant/viewMode'

export type TransportPolicyScope = 'transport'
type LogisticPolicyScope = 'logistic'
export type FoodPolicyScope = 'food'

export type PolicyScope =
  | TransportPolicyScope
  | LogisticPolicyScope
  | FoodPolicyScope
export interface PolicyConfig {
  currency: CurrencyCode
  personal_limit?: {
    frequency: 'WEEKLY' | 'DAILY' | 'MONTHLY' | 'ALL_TIME'
    amount: number
    is_unlimited: boolean
  }[]
  trips?: {
    frequency: 'WEEKLY' | 'DAILY' | 'MONTHLY' | 'ALL_TIME'
    amount: number
    is_unlimited: boolean
  }[]
}

export interface PolicyData {
  company_id: number
  end_date?: string
  id: number
  name: string
  policy_type: PolicyType
  policy_config: PolicyConfig
  start_date: string
  status: PolicyStatus
  total_employee: number
  allow_multiple?: boolean
}

export interface PolicyState {
  defaultLimit: PolicyData
  policies: PolicyData[]
  errors: ErrorData
  isLoading: boolean
  total: number
  itemsPerPage: number
  currentPage: number
  viewMode: string
}

export const initialState: PolicyState = {
  isLoading: false,
  defaultLimit: {} as PolicyData,
  policies: [],
  itemsPerPage: 10,
  currentPage: 1,
  total: 0,
  errors: null,
  viewMode: viewModeType.IDLE
}

export const getPolicies = createAsyncThunk(
  'policy/getPolicies',
  async (
    payload: Omit<GetListAPIPayload, 'group_ids' | 'query'>,
    { rejectWithValue }
  ) => {
    try {
      return await getPolicyAPI(payload)
    } catch (error) {
      const { status, data } = error.response
      if (data || status) return rejectWithValue({ data, status })
      return error
    }
  },
  {
    condition: (_, { getState }) => {
      const { error } = getState() as {
        error: ErrorState
      }
      if (
        error.status === 429 &&
        Math.floor(Date.now() / 1000) <= error.updateAt!
      ) {
        return false
      }
    },
    dispatchConditionRejection: true
  }
)

export const policySlice = createSlice({
  name: 'policy',
  initialState: initialState,
  reducers: {
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload
    },
    setItemsPerPage: (state, action) => {
      state.itemsPerPage = action.payload
    },
    resetPagination: (state) => {
      state.itemsPerPage = initialState.itemsPerPage
      state.currentPage = initialState.currentPage
    },
    setViewMode: (state, action) => {
      state.viewMode = action.payload || initialState.viewMode
    },
    resetState: () => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPolicies.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getPolicies.fulfilled, (state, action) => {
        const { policies, total } = action.payload.data
        state.isLoading = false
        state.errors = null
        state.total = total
        state.policies = policies
        if (policies?.[0]?.policy_type === PolicyType.EMPLOYEE_DEFAULT) {
          state.defaultLimit = policies[0]
        }
      })
      .addCase(
        getPolicies.rejected,
        (state, action: PayloadAction<any, string, any, any>) => {
          state.isLoading = false
          state.errors = action.payload || {
            status: 500,
            data: `${action.error.name}: ${action.error.message}`
          }
        }
      )
  }
})

export const {
  setCurrentPage,
  setItemsPerPage,
  setViewMode,
  resetPagination,
  resetState
} = policySlice.actions

export const policySelector = (state: RootState) => state.policy

export default policySlice.reducer
