import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { ErrorData } from 'features/error/errorSlice'
import { verifyEmployeeTokenAPI } from 'utils/api'

export interface VerifyEmployeeState {
  redirectUrl: string
  tokenStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
  tokenError: ErrorData
}

const initialState: VerifyEmployeeState = {
  redirectUrl: '',
  tokenStatus: 'idle',
  tokenError: null
}

export const getVerifyEmployeeToken = createAsyncThunk(
  'verifyEmployee/token',
  async (payload: string, { rejectWithValue }) => {
    try {
      return await verifyEmployeeTokenAPI(payload)
    } catch (error) {
      const { status, data } = error.response
      return rejectWithValue({ data, status })
    }
  }
)

const verifyEmployeeSlice = createSlice({
  name: 'verifyEmployee',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getVerifyEmployeeToken.pending, (state) => {
        state.tokenStatus = 'loading'
      })
      .addCase(getVerifyEmployeeToken.fulfilled, (state, action) => {
        state.tokenStatus = 'succeeded'
        state.redirectUrl = action.payload.data.url
      })
      .addCase(
        getVerifyEmployeeToken.rejected,
        (state, action: PayloadAction<any, string, any, any>) => {
          state.tokenStatus = 'failed'
          state.tokenError = action.payload
        }
      )
  }
})

export const verifyEmployeeState = (state: RootState) => state.verifyEmployee

export default verifyEmployeeSlice.reducer
