import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from 'app/store'
import { VoucherStatusType } from 'utils/constant/filterStatus'
import { getVouchersAPI } from 'utils/api'
import { viewModeType } from 'utils/constant/viewMode'
import { GetListAPIPayload } from 'features/employees/employeeSlice'
import { ErrorData, ErrorState } from 'features/error/errorSlice'

export type VoucherRuleServiceTypeData = {
  id: number
  name: string
}

export type VoucherRulePOIData = {
  name: string
  address: string
  latitude: number
  longitude: number
  intent: string[]
}

export type VoucherRuleTimeWindowData = {
  days: string[]
  start_time: string
  end_time: string
  is_every_day?: boolean
}

export interface VoucherData {
  promotion_id: number
  voucher_batch_id: string
  program_name: string
  program_description: string
  prefix: string
  validity_start_date: string
  validity_end_date: string
  enabled: boolean
  total_codes: number
  enrolled_codes: number
  created_at: string
  voucher_name: string
  voucher_description: string
  monetary_type: 'DEFAULT' | 'UNLIMITED' | 'PERCENTAGE'
  monetary_value: number
  discount_percentage: number
  max_reward_amount: number
  status: string
  service_types: VoucherRuleServiceTypeData[]
  poi: VoucherRulePOIData[]
  time_window: VoucherRuleTimeWindowData[]
}

export interface VoucherFilter {
  query: string
  status: string
}

export interface VoucherState {
  isLoading: boolean
  vouchers: VoucherData[]
  total: number
  itemsPerPage: number
  currentPage: number
  viewMode: string
  filter: VoucherFilter
  errors: ErrorData
}

export const initialState: VoucherState = {
  isLoading: false,
  vouchers: [],
  total: 0,
  itemsPerPage: 10,
  currentPage: 1,
  filter: {
    query: '',
    status: VoucherStatusType.ALL
  },
  viewMode: viewModeType.IDLE,
  errors: null
}

export const getVouchers = createAsyncThunk(
  'voucher/getVouchers',
  async (payload: GetListAPIPayload, { rejectWithValue, getState }) => {
    try {
      const getFilter: any = (getState() as RootState).voucher.filter
      const { status, query } = getFilter
      if (query !== '') payload = { ...payload, query }
      if (status !== VoucherStatusType.ALL) payload = { ...payload, status }
      return await getVouchersAPI(payload)
    } catch (error) {
      const { status, data } = error.response
      if (data || status) return rejectWithValue({ data, status })
      return error
    }
  },
  {
    condition: (_, { getState }) => {
      const { error } = getState() as {
        error: ErrorState
      }
      if (
        error.status === 429 &&
        Math.floor(Date.now() / 1000) <= error.updateAt!!
      ) {
        return false
      }
    },
    dispatchConditionRejection: true
  }
)

export const voucherSlice = createSlice({
  name: 'voucher',
  initialState: initialState,
  reducers: {
    setEnabledByID: (state, action) => {
      const { id, on } = action.payload
      const findVoucher = state.vouchers.find(
        (voucher) => voucher.promotion_id === id
      )
      if (findVoucher) {
        findVoucher.enabled = on
      }
    },
    setViewMode: (state, action) => {
      const { payload } = action
      state.viewMode = payload || initialState.viewMode
    },
    setFilter: (state, action) => {
      const { name, value } = action.payload
      if (name === 'status') {
        state.filter.status = value
      } else if (name === 'query') {
        state.filter.query = value
      }
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload
    },
    setItemsPerPage: (state, action) => {
      state.itemsPerPage = action.payload
    },
    resetPagination: (state) => {
      state.itemsPerPage = initialState.itemsPerPage
      state.currentPage = initialState.currentPage
    },
    resetState: () => initialState
  },
  extraReducers: (builder) => {
    builder.addCase(getVouchers.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getVouchers.fulfilled, (state, action) => {
      state.isLoading = false
      state.errors = null
      const { total, vouchers } = action.payload.data
      state.vouchers = vouchers || initialState.vouchers
      state.total = total
    })
    builder.addCase(
      getVouchers.rejected,
      (state, action: PayloadAction<any, string, any, any>) => {
        state.vouchers = initialState.vouchers
        state.total = initialState.total
        state.viewMode = initialState.viewMode
        state.isLoading = false
        state.errors = action.payload || {
          status: 500,
          data: `${action.error.name}: ${action.error.message}`
        }
      }
    )
  }
})

export const {
  setCurrentPage,
  setItemsPerPage,
  resetPagination,
  setEnabledByID,
  setFilter,
  setViewMode,
  resetState
} = voucherSlice.actions

export const voucherSelector = (state: RootState) => state.voucher

export default voucherSlice.reducer
