import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  isAnyOf
} from '@reduxjs/toolkit'

import { RootState } from 'app/store'
import { ErrorData } from 'features/error/errorSlice'
import { CompanyData, setCompanyId } from 'features/profile/profileSlice'
import { loginAPI, logoutAPI } from 'utils/api'
import {
  getProfile,
  getToken,
  getUsername,
  isExpired,
  setUser
} from 'utils/auth'
import { getLocale, setLocale as setLocaleStorage } from 'utils/locale'
import { removeAuthStorage } from 'utils/storage'
import i18n from 'i18n'

export type ProfilData = {
  user_id: number
  full_name: string
  email: string
  phone: string
  status: string
  role: string
  companies: CompanyData[]
} | null

export interface AuthState {
  username: string
  profile: ProfilData
  errors: ErrorData
  isLoading: boolean
  isExpired: boolean
  justLoggedOut: boolean
  locale: string
}

export interface LoginPayload {
  username: string
  password: string
}

const initialState: AuthState = {
  username: getUsername(),
  profile: getProfile(),
  errors: null,
  isLoading: false,
  isExpired: isExpired(getToken()),
  justLoggedOut: false,
  locale: getLocale()
}

export const doLogin = createAsyncThunk(
  'auth/doLogin',
  async (payload: LoginPayload, { rejectWithValue, dispatch }) => {
    try {
      const { username, password } = payload
      const response = await loginAPI({ email: username, password })
      const { user_profile } = response.data
      if (user_profile.companies.length === 0) {
        const err = {
          response: {
            status: 1001,
            data: {
              message: i18n.t('errors.no_companies')
            }
          }
        }
        throw err
      }
      dispatch({
        type: setCompanyId.type,
        payload: user_profile.companies[0].id
      })
      return response
    } catch (error) {
      const { status, data } = error.response
      if (status === 403) {
        const { token } = data.data
        data.token = token
      }
      if (status === 429) {
        const { message } = data?.errors?.[0]
        data.message = message
      }
      return rejectWithValue({ data, status })
    }
  }
)

export const doLogout = createAsyncThunk(
  'auth/doLogout',
  async (_, { rejectWithValue }) => {
    try {
      return await logoutAPI()
    } catch (error) {
      const { status, data } = error.response
      if (data || status) return rejectWithValue({ data, status })
      return error
    }
  }
)

export const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    setLocale: (state, action) => {
      const { payload } = action
      state.locale = payload
      setLocaleStorage(payload)
    },
    resetErrors: (state) => {
      state.errors = initialState.errors
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(doLogin.pending, (state) => {
        state.isLoading = true
      })
      .addCase(doLogin.fulfilled, (state, action) => {
        setUser(action.payload.data)
        const { user_profile } = action.payload.data
        state.isLoading = false
        state.errors = null
        state.username = user_profile.full_name
        state.profile = user_profile
        state.isExpired = false
        state.justLoggedOut = false
      })
      .addCase(
        doLogin.rejected,
        (state, action: PayloadAction<any, string, any, any>) => {
          state.isLoading = false
          state.errors = action.payload
          state.isExpired = true
          state.justLoggedOut = false
        }
      )
      .addMatcher(isAnyOf(doLogout.fulfilled, doLogout.rejected), (state) => {
        state.isLoading = false
        state.errors = null
        state.username = ''
        state.profile = null
        state.isExpired = true
        removeAuthStorage()
        state.justLoggedOut = true
      })
  }
})

export const { setLocale, resetErrors } = authSlice.actions
export const authSelector = (state: RootState) => state.auth
export const isLoggedIn = (state: RootState) => {
  const { isExpired: isExpiredAuth } = state.auth
  return !isExpiredAuth && !isExpired(getToken())
}
export default authSlice.reducer
