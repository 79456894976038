import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  EntityState,
  PayloadAction
} from '@reduxjs/toolkit'

import {
  EmployeeData,
  GetListAPIPayload
} from 'features/employees/employeeSlice'
import { RootState } from 'app/store'
import { getEmployeesAPI } from 'utils/api'
import { ErrorData, ErrorState } from 'features/error/errorSlice'
import { SelectionType } from 'utils/constant/filterStatus'

const employeesAdapter = createEntityAdapter<EmployeeData>()

export interface EmployeePolicyState {
  preview: {
    employees: EntityState<EmployeeData>
    isLoading: boolean
    errors: ErrorData
    total: number
    currentPage: number
    itemsPerPage: number
    filter: {
      query: string
      status: string
      groupIds: string[]
    }
  }
  addPolicy: {
    select: {
      employees: EntityState<EmployeeData>
    }
  }
  editPolicy: {
    select: {
      employees: EntityState<EmployeeData>
    }
    list: {
      employees: EntityState<EmployeeData>
      total: number
      limit: number
      errors: ErrorData
      isLoading: boolean
      fetchCount: number
    }
  }
}

export const initialState: EmployeePolicyState = {
  preview: {
    employees: employeesAdapter.getInitialState(),
    isLoading: true,
    errors: null,
    currentPage: 1,
    itemsPerPage: 10,
    total: 0,
    filter: {
      status: SelectionType.ALL,
      query: '',
      groupIds: []
    }
  },
  addPolicy: {
    select: {
      employees: employeesAdapter.getInitialState()
    }
  },
  editPolicy: {
    select: {
      employees: employeesAdapter.getInitialState()
    },
    list: {
      employees: employeesAdapter.getInitialState(),
      total: 0,
      errors: null,
      isLoading: false,
      limit: 500,
      fetchCount: 1
    }
  }
}

function employeePolicyThunk(name: string) {
  return createAsyncThunk(
    name,
    async (payload: GetListAPIPayload, { rejectWithValue }) => {
      try {
        return await getEmployeesAPI({
          ...payload
        })
      } catch (error) {
        const { status, data } = error.response
        if (data || status) return rejectWithValue({ data, status })
        return error
      }
    },
    {
      condition: (_, { getState }) => {
        const { error } = getState() as {
          error: ErrorState
        }
        if (
          error.status === 429 &&
          Math.floor(Date.now() / 1000) <= error.updateAt!
        ) {
          return false
        }
      },
      dispatchConditionRejection: true
    }
  )
}

export const getEmployeePolicyPreview = employeePolicyThunk(
  'employeePolicy/getEmployeePolicyPreview'
)

export const getEmployeeEditPolicyListed = employeePolicyThunk(
  'employeePolicy/getEmployeeEditPolicyList'
)

export const EmployeePolicySlice = createSlice({
  name: 'employeePolicy',
  initialState: initialState,
  reducers: {
    selectEmployeesAddPolicy: (state, action) => {
      employeesAdapter.setAll(state.addPolicy.select.employees, action.payload)
    },
    selectEmployeesEditPolicy: (state, action) => {
      employeesAdapter.setAll(state.editPolicy.select.employees, action.payload)
    },
    setCurrentPagePreview: (state, action) => {
      state.preview.currentPage = action.payload
    },
    setItemsPerPagePreview: (state, action) => {
      state.preview.itemsPerPage = action.payload
    },
    setFilterPreview: (state, action) => {
      const { name, value } = action.payload
      if (name === 'SEARCH_QUERY') {
        state.preview.filter.query = value
      } else if (name === 'STATUS') {
        state.preview.filter.status = value
      } else if (name === 'GROUPIDS') {
        const isValueExist = state.preview.filter.groupIds.includes(value)
        const filterGroupIds = state.preview.filter.groupIds.filter(
          (group) => group !== value
        )
        if (value === '') {
          state.preview.filter.groupIds = []
        } else if (isValueExist) {
          state.preview.filter.groupIds = [...filterGroupIds]
        } else {
          state.preview.filter.groupIds = [...filterGroupIds, value]
        }
      }
    },
    resetState: () => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEmployeePolicyPreview.fulfilled, (state, action) => {
        const { employees, total } = action.payload.data
        state.preview.isLoading = false
        state.preview.errors = null
        state.preview.total = total
        employeesAdapter.setAll(state.preview.employees, employees)
      })
      .addCase(
        getEmployeePolicyPreview.rejected,
        (state, action: PayloadAction<any, string, any, any>) => {
          state.preview.isLoading = false
          state.preview.errors = action.payload || {
            status: 500,
            data: `${action.error.name}: ${action.error.message}`
          }
        }
      )
      .addCase(getEmployeeEditPolicyListed.pending, (state) => {
        state.editPolicy.list.isLoading = true
      })
      .addCase(getEmployeeEditPolicyListed.fulfilled, (state, action) => {
        const { employees, total } = action.payload.data
        state.editPolicy.list.isLoading = false
        state.editPolicy.list.errors = null
        state.editPolicy.list.total = total
        employeesAdapter.upsertMany(
          state.editPolicy.select.employees,
          employees
        )
        employeesAdapter.upsertMany(state.editPolicy.list.employees, employees)
        if (
          total >=
          state.editPolicy.list.fetchCount * state.editPolicy.list.limit
        ) {
          state.editPolicy.list.isLoading = true
        } else {
          state.editPolicy.list.isLoading = false
        }
        state.editPolicy.list.fetchCount += 1
      })
      .addCase(
        getEmployeeEditPolicyListed.rejected,
        (state, action: PayloadAction<any, string, any, any>) => {
          state.editPolicy.list.isLoading = false
          state.editPolicy.list.errors = action.payload || {
            status: 500,
            data: `${action.error.name}: ${action.error.message}`
          }
        }
      )
  }
})

export const {
  selectEmployeesAddPolicy,
  selectEmployeesEditPolicy,
  setCurrentPagePreview,
  setItemsPerPagePreview,
  setFilterPreview,
  resetState
} = EmployeePolicySlice.actions

export const employeesPolicyPreviewSelector = employeesAdapter.getSelectors(
  (state: RootState) => state.employeePolicy.preview.employees
)
export const employeesAddPolicySelectedSelector = employeesAdapter.getSelectors(
  (state: RootState) => state.employeePolicy.addPolicy.select.employees
)
export const employeesEditPolicyListedSelector = employeesAdapter.getSelectors(
  (state: RootState) => state.employeePolicy.editPolicy.list.employees
)
export const employeesEditPolicySelectedSelector = employeesAdapter.getSelectors(
  (state: RootState) => state.employeePolicy.editPolicy.select.employees
)
export const employeesPolicyPreviewStatusSelector = (state: RootState) =>
  state.employeePolicy.preview
export const employeesEditPolicyListedStatusSelector = (state: RootState) =>
  state.employeePolicy.editPolicy.list

export default EmployeePolicySlice.reducer
