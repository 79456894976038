import SecureLS from 'secure-ls'

export const storage = new SecureLS()

export const removeAuthStorage = () => {
  storage.remove('_token')
  storage.remove('_profile')
  storage.remove('_company_id')
  storage.remove('_username')
}
