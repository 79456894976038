import { createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'

export interface VoucherTabsState {
  isFormChanged: {
    addVoucher: boolean
  }
}

export const initialState: VoucherTabsState = {
  isFormChanged: {
    addVoucher: false
  }
}

export const voucherTabsSlice = createSlice({
  name: 'voucherTabs',
  initialState: initialState,
  reducers: {
    setIsFormChangedAddVoucher: (state, action) => {
      state.isFormChanged.addVoucher = action.payload
    },
    resetState: (state) => {
      state.isFormChanged = initialState.isFormChanged
    }
  }
})

export const {
  setIsFormChangedAddVoucher,
  resetState
} = voucherTabsSlice.actions

export const voucherTabsSelector = (state: RootState) => state.voucherTabs

export default voucherTabsSlice.reducer
