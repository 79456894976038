import { createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'

type TransportLimitTab = 'limit'
type FoodLimitTab = 'limit/food'
type DeliveryLimitTab = 'limit/delivery'
type TransportRuleTab = 'rules'
type FoodRuleTab = 'rules/food'

export type PolicyTabs =
  | TransportLimitTab
  | FoodLimitTab
  | DeliveryLimitTab
  | TransportRuleTab
  | FoodRuleTab

export interface PolicyTabsState {
  active: '' | PolicyTabs
  isFormChanged: {
    addLimit: boolean
    editLimit: boolean
    addRules: boolean
    editRules: boolean
  }
}

export const initialState: PolicyTabsState = {
  active: '',
  isFormChanged: {
    addLimit: false,
    editLimit: false,
    addRules: false,
    editRules: false
  }
}

export const policyTabsSlice = createSlice({
  name: 'policyTabs',
  initialState: initialState,
  reducers: {
    setActive: (state, action) => {
      state.active = action.payload
    },
    setIsFormChangedAddLimit: (state, action) => {
      state.isFormChanged.addLimit = action.payload
    },
    setIsFormChangedEditLimit: (state, action) => {
      state.isFormChanged.editLimit = action.payload
    },
    setIsFormChangedAddRule: (state, action) => {
      state.isFormChanged.addRules = action.payload
    },
    setIsFormChangedEditRule: (state, action) => {
      state.isFormChanged.editRules = action.payload
    },
    resetState: (state) => {
      state.isFormChanged = initialState.isFormChanged
    }
  }
})

export const {
  setActive,
  setIsFormChangedAddLimit,
  setIsFormChangedEditLimit,
  setIsFormChangedAddRule,
  setIsFormChangedEditRule,
  resetState
} = policyTabsSlice.actions

export const policyTabsSelector = (state: RootState) => state.policyTabs

export default policyTabsSlice.reducer
