/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/core'
import tw from 'twin.macro'
import { useTranslation } from 'react-i18next'
import Button from '@asphalt-react/button'
import { useSelector } from 'react-redux'

import Img from './Img'
import { errorStatusSelector } from 'features/error/errorSlice'

type InternalServerProps = {
  noBorder?: boolean
}

const InternalServer = ({ noBorder }: InternalServerProps) => {
  const { t } = useTranslation()
  const { status } = useSelector(errorStatusSelector)

  const refreshPage = () => {
    if (status === 1001) {
      window.location.href = '/login'
    } else {
      window.location.reload()
    }
  }

  let title
  let desc
  if (status === 429) {
    title = t('errors.429')
    desc = t('errors.429_desc')
  } else if (status === 1001) {
    title = t('errors.account_inactive')
    desc = t('errors.no_companies')
  } else {
    title = t('errors.500')
    desc = t('errors.500_desc')
  }

  return (
    <div
      tw="w-full flex flex-wrap items-center xxl:(px-64 py-40)"
      css={[!noBorder && tw`border border-gray10`]}
    >
      <div tw="w-full p-8 lg:(w-1/2 p-12)">
        <Img src="/images/error/troubleshoot.png" alt="internal server error" />
      </div>
      <div tw="w-full mb-8 text-center box-border px-8 lg:(w-1/2 text-left px-12 mb-0)">
        <h3 tw="md:text-28 font-semibold w-full">{title}</h3>
        <p tw="text-sm md:text-lg w-full mb-4">{desc}</p>
        <Button outline size="m" onClick={refreshPage}>
          {status === 1001
            ? t('shared.ok_button')
            : t('shared.page_refresh_button')}
        </Button>
      </div>
    </div>
  )
}

export default InternalServer
