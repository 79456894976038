import { format, parseISO } from 'date-fns'

import {
  CountryCode,
  idID,
  UTC7H,
  defaultUTCH,
  IDCountryCode
} from './constant/country'
import { getLocale } from './locale'

const miliseconds = 1000

export const toLocaleISOString = (
  date: any,
  range = 'DEFAULT',
  countryCode: CountryCode
) => {
  let datum
  const hour = defaultUTCH[countryCode] || UTC7H
  switch (range) {
    case 'START':
      datum = date.setUTCHours(0 - hour)
      break
    case 'END':
      datum = date.setUTCHours(24 - (hour + 1), 59, 59)
      break
    default:
      datum = Date.parse(date)
      break
  }

  return new Date(datum).toISOString()
}

export const toTimestamp = (date: Date, range = 'DEFAULT') => {
  let datum
  switch (range) {
    case 'START':
      datum = date.setHours(0, 0, 0)
      break
    case 'END':
      datum = date.setHours(23, 59, 59)
      break
    default:
      datum = Date.parse(date.toDateString())
      break
  }

  return datum / miliseconds
}

export const toTimestampTz = (
  date: Date,
  range = 'DEFAULT',
  countryCode: CountryCode
) => {
  let datum
  const hour = defaultUTCH[countryCode]
  switch (range) {
    case 'START':
      datum = date.setUTCHours(0 - hour)
      break
    case 'END':
      datum = date.setUTCHours(23 - hour, 59, 59)
      break
    default:
      datum = Date.parse(date.toDateString())
      break
  }

  return datum / miliseconds
}

export const formatYYYYMMDD = (date: Date | null = null) => {
  if (!date || typeof date.getMonth !== 'function') {
    return ''
  }
  const m = date.getMonth() + 1
  const d = date.getDate()
  return [date.getFullYear(), m < 10 ? '0' + m : m, d < 10 ? '0' + d : d].join(
    '-'
  )
}

export const formatDateTime = (
  date: string | Date,
  options = { dateStyle: 'long' } as Intl.DateTimeFormatOptions,
  locale = getLocale()
) => {
  const d = new Date(date)
  return new Intl.DateTimeFormat(locale, options).format(d)
}

export const getCurrentYear = () => {
  const d = new Date()
  return d.getFullYear()
}

export const getCurrentMonth = () => {
  const d = new Date()
  const options = { month: 'long' } as Intl.DateTimeFormatOptions
  return formatDateTime(d, options)
}

export const getDateToFilename = (
  date = new Date(),
  options = {
    day: 'numeric',
    month: 'short',
    year: 'numeric'
  } as Intl.DateTimeFormatOptions
) => {
  const regex = / /g
  const currentDate = formatDateTime(date, options, idID)

  return currentDate.replace(regex, '_').toLowerCase()
}

export const formatHHMMToDate = (time: string) => {
  const splitTime = time.split(':')
  const date = new Date()
  date.setHours(parseInt(splitTime[0]))
  date.setMinutes(parseInt(splitTime[1]))
  return date
}

export const formatTimeAMPM = (time: string) =>
  format(formatHHMMToDate(time), 'hh:mm aa')

export const formatDateTimeByCountryCode = (
  date: string,
  countryCode: CountryCode
) => {
  const splitDateTz = date.split('+')
  const dateTimeParsed = splitDateTz ? parseISO(splitDateTz[0]) : null
  if (!dateTimeParsed) return null
  let gmtFormat = ''
  const isIDCompany = countryCode === IDCountryCode
  if (isIDCompany) {
    switch (splitDateTz[1]) {
      case '07:00':
        gmtFormat = 'WIB'
        break
      case '08:00':
        gmtFormat = 'WITA'
        break
      case '09:00':
        gmtFormat = 'WIT'
        break
    }
  }
  return format(dateTimeParsed, 'dd/MM/yyy, HH:mm ') + gmtFormat
}
