import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { ErrorData, ErrorState } from 'features/error/errorSlice'
import { getOverviewAPI } from 'utils/api'

export interface OverviewData {
  employee: {
    active_employee: number
    total_employee: number
  }
  ride: {
    by_total_employee: number
    total_complete_rides: number
  }
  spent: {
    by_total_employee: number
    total_spent: number
    transport_spent: number
    logistic_spent: number
    food_spent: number
    cc_spent: BreakdownSpentByScope
    post_paid_spent: BreakdownSpentByScope
  }
  company_limit: {
    amount: number
    balance: number
    is_unlimited: boolean
  }
}

export interface BreakdownSpentByScope {
  total_spent: number
  transport_spent: number
  logistic_spent: number
  food_spent: number
}

export interface FreData {
  employee_or_group: boolean
  onboarding_preview: boolean
  policy: boolean
}

export interface HomeState {
  isLoading: boolean
  overview: OverviewData
  fre: FreData
  errors: ErrorData
}

const initialState: HomeState = {
  isLoading: true,
  overview: {} as OverviewData,
  fre: {} as FreData,
  errors: null
}

export const getOverview = createAsyncThunk(
  'home/getOverview',
  async (_, { rejectWithValue }) => {
    try {
      return await getOverviewAPI()
    } catch (error) {
      const { status, data } = error.response
      if (data || status) return rejectWithValue({ data, status })
      return error
    }
  },
  {
    condition: (_, { getState }) => {
      const { error } = getState() as {
        error: ErrorState
      }
      if (
        error.status === 429 &&
        Math.floor(Date.now() / 1000) <= error.updateAt!
      ) {
        return false
      }
    },
    dispatchConditionRejection: true
  }
)

export const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOverview.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getOverview.fulfilled, (state, action) => {
        const { company_statistics, first_run_experience } = action.payload.data
        state.isLoading = false
        state.errors = null
        state.overview = company_statistics
        state.fre = first_run_experience
      })
      .addCase(
        getOverview.rejected,
        (state, action: PayloadAction<any, string, any, any>) => {
          state.isLoading = false
          state.errors = action.payload || {
            status: 500,
            data: `${action.error.name}: ${action.error.message}`
          }
        }
      )
  }
})

export const homeSelector = (state: RootState) => state.home

export default homeSlice.reducer
