/** @jsx jsx */
import { jsx } from '@emotion/core'
import tw from 'twin.macro'

type SizeType = {
  s: string
  m: string
  l: string
}

export default function GoCorp({
  linkTo,
  size,
  light,
  navbar
}: {
  linkTo?: string
  size: 's' | 'm' | 'l'
  light: boolean
  navbar: boolean
}) {
  const svgSize: SizeType = {
    s: '16',
    m: '24',
    l: '40'
  }

  const GoCorpSVG = () => {
    return (
      <svg
        role="img"
        aria-label="GoCorp Logo"
        height={svgSize[size]}
        viewBox="0 0 150 28"
        css={[
          light
            ? tw`fill-current text-white`
            : tw`fill-current text-gocorp-black80`,
          navbar && tw`md:text-white`
        ]}
      >
        <title>GoCorp Logo</title>
        <circle cx="12.2" cy="12.4" fill="#00aed6" r="11.9" />
        <g fill="#fff">
          <path d="m19.9 15.2-1.1-.9c-.1-.1-.2-.1-.3-.1s-.2.1-.2.2l-.3 1.5c0 .1 0 .3.1.3.1.1.2.1.3.1l.2-.1c.2.9-.3 1.7-1.1 2-.4.1-.8.1-1.2 0-.9-.4-1.3-1.4-.9-2.3.3-.8 1.1-1.2 2-1 .1 0 .2 0 .2-.1l.1-.5c0-.1 0-.1 0-.2 0 0-.1-.1-.1-.1-.4-.1-.8-.1-1.1 0-.6.1-1.2.5-1.6 1-.8 1-.8 2.4 0 3.4.5.6 1.2 1 2.1 1 .4 0 .8-.1 1.2-.3 1.1-.6 1.7-1.9 1.4-3.1l.3-.1c.1 0 .2-.1.2-.3s-.1-.3-.2-.4z" />
          <path d="m8.9 7.1c1.8.1 3.7.1 5.5 0 .1 0 .2-.1.2-.2.1-.4.2-.8.2-1.2 0-.7-.5-1.2-1.1-1.3-1.3-.1-2.7-.1-4 0-.6.1-1.1.6-1.2 1.2 0 .4 0 .9.2 1.3 0 .1.1.2.2.2z" />
          <path d="m17 12.8c.3 0 .6 0 .9.1 0-1.5-.1-3-.2-4.4-.2-1.1-1-1.9-2.1-2 0 .4-.1.8-.2 1.2 0 .1-.1.2-.3.2-2.3.1-4.5.1-6.8-.1-.1 0-.2-.1-.3-.2-.1-.4-.1-.8-.1-1.2-1.1.1-1.9 1-2.1 2-.3 2.8-.3 5.7 0 8.6.2 1.1 1.1 2 2.3 2.1 2.1.3 4.2.3 6.3.1-1.5-1.5-1.4-3.9 0-5.3.6-.7 1.6-1.1 2.6-1.1zm-5 1h-3.6c-.3 0-.5-.3-.4-.6 0-.2.2-.4.4-.4h3.6c.3 0 .5.3.4.6 0 .2-.2.4-.4.4zm-3.5-2.4c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h6.5c.3 0 .5.2.5.5s-.2.5-.5.5z" />
        </g>
        <path d="m31.1 20.4 2.7-2.2c1 1.5 2.7 2.3 4.5 2.2 2.1 0 3.6-1.3 3.6-3.1v-1h-.1c-1.1 1.1-2.7 1.7-4.3 1.6-3.9.1-7.1-2.9-7.2-6.8s2.9-7.1 6.8-7.2h.3c1.6-.1 3.2.5 4.4 1.6h.1v-1.3h3.6v13c0 3.8-3 6.4-7.2 6.4-2.8.2-5.4-1-7.2-3.2zm10.6-8.7v-1.6c0-1.5-1.7-2.9-3.6-2.9-2.4 0-4 1.5-4 3.7-.1 1.9 1.3 3.6 3.3 3.8h.6c2.1 0 3.7-1.4 3.7-3z" />
        <path d="m47.3 10.9c0-4 3.1-7.1 7.5-7.1s7.5 3.1 7.5 7.1-3.1 7.1-7.5 7.1c-4.3 0-7.5-3.1-7.5-7.1zm11.3 0c0-2.1-1.7-3.8-3.8-3.8s-3.8 1.7-3.8 3.8 1.7 3.8 3.8 3.8c2 .1 3.7-1.4 3.8-3.4.1-.2.1-.3 0-.4z" />
        <path d="m63.9 10.9c0-3.9 3.2-7.1 7.1-7.1h.3c3.4 0 6.1 1.9 6.9 4.7l-3.5 1c-.5-1.5-1.9-2.5-3.5-2.4-2.1.1-3.7 1.8-3.7 3.9.1 2 1.7 3.6 3.7 3.7 1.6.1 3-.9 3.4-2.4l3.5 1c-.8 2.8-3.5 4.7-6.9 4.7-3.9.2-7.2-2.9-7.4-6.8.1-.1.1-.2.1-.3z" />
        <path d="m79.4 10.9c0-4 3.1-7.1 7.5-7.1s7.5 3.1 7.5 7.1-3.1 7.1-7.5 7.1c-4.3 0-7.5-3.1-7.5-7.1zm11.4 0c0-2.1-1.7-3.8-3.8-3.8s-3.8 1.7-3.8 3.8 1.7 3.8 3.8 3.8c2 .1 3.7-1.4 3.8-3.4 0-.2 0-.3 0-.4z" />
        <path d="m96.5 4.2h3.5v1.3c1-1 2.5-1.6 3.9-1.5.7 0 1.5.1 2.2.4l-.8 3.4c-.6-.2-1.3-.3-1.9-.3-2 0-3.2 1.3-3.2 4.1v6.1h-3.7z" />
        <path d="m107.6 4.2h3.6v1.2h.1c1.2-1.1 2.7-1.6 4.3-1.6 3.9.1 7 3.3 6.9 7.2-.1 3.8-3.1 6.8-6.9 6.9-1.5 0-3-.5-4.2-1.4v6.7h-3.7v-19zm11.1 6.7c.1-2-1.4-3.7-3.4-3.8-.1 0-.3 0-.4 0-2 0-3.6 1.3-3.6 2.9v1.7c0 1.6 1.6 2.9 3.7 2.9 2 .1 3.7-1.5 3.8-3.4-.1-.1-.1-.2-.1-.3z" />
      </svg>
    )
  }
  return linkTo ? (
    <a href={linkTo}>
      <GoCorpSVG />
    </a>
  ) : (
    <GoCorpSVG />
  )
}

GoCorp.defaultProps = {
  size: 'm',
  light: false,
  navbar: false
}
