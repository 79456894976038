import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from 'app/store'
import { ErrorData, ErrorState, setStatus } from 'features/error/errorSlice'
import { getCompanyDetailAPI } from 'utils/api'
import type {
  CountryCode,
  BillingType,
  CCRegistrationStatus
} from 'utils/constant/country'

interface CompanyFeatureToggles {
  VOUCHER?: {
    scope: string[]
  }
}

export interface CompanyData {
  id: number
  display_name: string
  phone: string
  address: string
  country_code: CountryCode
  billing_type: BillingType
  cc_details: {
    token_expiry: string
    registration_order_id: string
    masked_card: string
    name: string
    registration_status: CCRegistrationStatus
    failing: boolean
  }
  features: CompanyFeatureToggles
  payment_method_label: string
}

export interface CompanyState {
  company: CompanyData | null
  errors: ErrorData
  isLoading: boolean
}

export const initialState: CompanyState = {
  company: null,
  errors: null,
  isLoading: false
}

export const getCompanyDetail = createAsyncThunk(
  'company/getCompanyDetail',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      return await getCompanyDetailAPI()
    } catch (error) {
      const { status, data } = error.response
      if (data || status) {
        dispatch(setStatus({ status, data }))
        return rejectWithValue({ data, status })
      }
      return error
    }
  },
  {
    condition: (_, { getState }) => {
      const { error } = getState() as {
        error: ErrorState
      }
      if (
        error.status === 429 &&
        Math.floor(Date.now() / 1000) <= error.updateAt!
      ) {
        return false
      }
    },
    dispatchConditionRejection: true
  }
)

export const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCompanyDetail.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getCompanyDetail.fulfilled, (state, action) => {
        const { data } = action.payload
        state.isLoading = false
        state.errors = null
        state.company = data
      })
      .addCase(
        getCompanyDetail.rejected,
        (state, action: PayloadAction<any, string, any, any>) => {
          state.isLoading = false
          state.errors = action.payload || {
            status: 500,
            data: `${action.error.name}: ${action.error.message}`
          }
        }
      )
  }
})

export const companyDetailSelector = (state: RootState) => state.company

export default companySlice.reducer
